body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custom */
.table-responsive table thead tr th {
  text-align: center !important;
}
.Group_GroupMain__CDpJa .Group_AddStyle__nVv26 {
  bottom: 34px !important;
}
.topNone {
  top: 0px !important;
}
.GroupPopupMain select {
  padding: 6px 4px !important;
}
.AutoPopupMain select {
  padding: 6px 4px !important;
}
.no-data-msg {
  text-align: center;
}
.Group_AddStyle__zFRS6 {
  bottom: 50px !important;
}
.link-text {
  color: blue;
  cursor: pointer;
}
.accordionManagementMain ul li.child-perm span {
  left: -10px !important;
}
button {
  text-transform: capitalize !important;
}
button:disabled,
button[disabled] {
  background-color: lightgray !important;
  cursor: not-allowed;
}
.cursorPointer {
  cursor: pointer;
}
.examPage .tabMainAllCommon li img {
  cursor: pointer;
}
.fill-ans {
  color: #0d6efd;
  text-decoration: underline;
}
.timeTable .attendenceCalender .rbc-day-slot .rbc-timeslot-group {
  background-color: #f5fad0 !important;
}
.timeTable .attendenceCalender .rbc-event {
  background-color: #eaf2fe !important;
  color: black !important;
  border: 1px solid #225fbb !important;
}
.register-form input {
  margin-bottom: 10px !important;
}
.register-form .register-form-container {
  padding: 17px 50px !important;
}
.jobPage .jobDeatilsTabMain .nav-item button {
  background-color: transparent !important;
}
.jobPage .dragDrop {
  display: flex !important;
  background: #ffff !important;
  padding: 0px 10px !important;
  overflow: auto;
}
.jobPage .dragDrop .ProfileFlexMain h5 {
  font-size: 10px !important;
}
.jobPage .dragDrop .dragDropHead h5 {
  margin-left: 37px !important;
}
.ratingStar:hover {
  color: #1b4e9b !important;
}
.hrmsOverAllDisable {
  border: 2px gray solid !important;
}
.hrmsOverAllDisable i {
  color: grey !important;
}
.hrmsOverAllDisable:hover {
  cursor: pointer;
}
.ratingStar:hover {
  cursor: pointer;
}
.activeBorder {
  border-color: #0d6efd !important;
}
.bookedButton {
  border: none;
  padding: 3px 10px;
  margin: 5px 0px;
  background-color: #bccf31 !important;
  color: #fff; 
  cursor: not-allowed; 
}

.availableButton {
  border: none;
  padding: 3px 10px;
  margin: 5px 0px;
  background-color: #e0e0e0 !important; 
  color: #000;
}
.availableButton :hover{
  background-color: #aaa9a9;
}
.txtCapital {
  text-transform: capitalize !important;
}
.checkBox-space {
  display: flex;
  justify-content: space-between;
}
.checkBox-space .newCheckForm .form-check-input:checked {
  background-color: #09e03a !important;
  border-color: #09e03a !important;
}
.interviewDocsPreview button {
  float: right;
  margin-bottom: 8px;
}
.statusImgDiv {
  float: left;
}
.err-message {
  font-size: 12px;
  color: red;
}
