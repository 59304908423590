@import "react-big-calendar/lib/sass/styles";
@import "react-big-calendar/lib/addons/dragAndDrop/styles";
@import "@material/slider/styles";
@import "~video-react/styles/scss/video-react";
@import "react-toastify/dist/ReactToastify.css";
@import "react-calendar/dist/Calendar.css";

$primary-color: #1b4e9b;
$main-body: #f0f5fb;
$secondary-color: #bccf31;
$sub-text: #9d9e9e;

@mixin TosterCommon {
  border: none;
  border-radius: 50px !important;

  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  color: #fff !important;
  min-height: 50px !important;
  font-size: 13px;
  font-weight: 400;
}

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background: #f0f5fb !important;
}

a {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  outline: none !important;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

a:hover {
  color: #111 !important;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin: 0;
  padding: 0;
}

p {
  padding: 0;
  color: #444;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  margin: 0 0 15px 0;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: #151515;
}

input,
select,
button {
  outline: none !important;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #1b4e9b;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

html {
  scrollbar-color: #1b4e9b #444;
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #444;
}

body::-webkit-scrollbar-thumb {
  background: #1b4e9b;
}

body::-moz-scrollbar {
  width: 8px;
}

body::-moz-scrollbar-track {
  background: #444;
}

body::-moz-scrollbar-thumb {
  background: #1b4e9b;
}

body::-o-scrollbar {
  width: 8px;
}

body::-o-scrollbar-track {
  background: #444;
}

body::-o-scrollbar-thumb {
  background: #1b4e9b;
}

body::-ms-scrollbar {
  width: 8px;
}

body::-ms-scrollbar-track {
  background: #444;
}

body::-ms-scrollbar-thumb {
  background: #1b4e9b;
}

.chatRoomBody {
  overflow: hidden;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

.dropdown-menu {
  padding: 5px 14px !important;
  font-size: 14px;
}

.dropdown-item {
  font-size: 16px;
  padding: 10px 0px !important;
  color: #524949 !important;
  border-bottom: 1px #ddd solid !important;

  &:last-child {
    border-bottom: none !important;
  }
}

.dropdown-item:focus,
.dropdown-item:hover {
  &:hover {
    background: #fff;
    color: #1b4e9b !important;
  }
}

.commonPopupmainSecCommon {
  padding: 20px 20px;
  position: relative;

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: -33px;
    top: -38px;

    img {
      width: 45px;
      object-fit: contain;
    }
  }

  .AddPopupSubmit {
    width: 100%;
    padding: 10px 10px;
    background-color: #1b4e9b;
    border: none;
    color: #ffff;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 400;
  }

  .form-control {
    padding: 10px 10px;
    background: #fff;
    font-size: 14px;
    font-weight: 400 !important;
    width: 100%;
    color: #000;
    transition: width 0.5s;
    margin-bottom: 15px;
  }

  .form-control:focus {
    box-shadow: none !important;
  }

  .FlexUnderForm {
    display: flex;

    span {
      margin-right: 10px;
      cursor: pointer;
    }

    .Undo {
      margin-right: 20px;

      img {
        width: 13px;
        object-fit: contain;
      }
    }

    .dots {
      img {
        width: 15px;
        object-fit: contain;
      }
    }

    .tools {
      margin-right: 20px;
      margin-bottom: 5px;

      img {
        width: 8px;
        object-fit: contain;
      }

      .boldnew {
        width: 9px;
        object-fit: contain;
      }
    }
  }
}

.headerMainAll {
  padding-left: 270px;
  padding-top: 0px;
  width: 100%;
  padding-right: 17px;
  z-index: 1000;

  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.scrolled {
  background: #f7f7f7;
  -webkit-box-shadow: -5px 1px 32px -9px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -5px 1px 32px -9px rgba(0, 0, 0, 0.25);
  box-shadow: -5px 1px 32px -9px rgba(0, 0, 0, 0.25);
}

.allPageLayOutBg {
  position: relative;
  top: 0px;
  padding-left: 279px;
  padding-top: 19px;
  padding-right: 15px;
  padding-bottom: 10px;

  @media screen and (max-width: 992px) {
    padding-top: 42px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.App {
  text-align: center;
  color: $primary-color;
  background-color: $sub-text;
}

.main1div {
  .card-body {
    padding: 0px !important;
    margin-bottom: 10px;
  }

  .card {
    background-color: transparent !important;
    border: none !important;
    padding: 0px !important;
    width: 100%;
  }

  .card-header {
    background-color: transparent !important;
    border: none !important;
    padding: 0px !important;
    margin-bottom: 10px;

    .buttonCommonStyle {
      font-weight: 300;
      font-size: 15px;
      border-radius: 30px;
      border: none;
      width: 100%;
      background: none !important;
      color: #000;
      position: relative;
      text-align: left;
      padding: 10px 44px;

      img {
        position: absolute;
        left: 14px;
        top: 12px;
        margin-bottom: 0px;
        width: 24px;
        height: 24px;
      }
    }

    .activebuttonCommonStyle {
      background-color: $primary-color;
      font-weight: 300;
      font-size: 15px;
      border-radius: 30px;
      border: none;
      width: 100%;
      color: #fff;
      position: relative;
      text-align: left;
      padding: 10px 44px;

      img {
        position: absolute;
        left: 14px;
        top: 12px;
        margin-bottom: 0px;
        width: 24px;
        height: 24px;
      }
    }

    .activeBgMain {
      background: #1b4e9b !important;
    }
  }

  .nav-link {
    color: #918c8c;
    font-size: 12px;
    padding: 5px 19px 10px;
    transition: 0.4s;
  }

  .nav-link.active {
    color: #000 !important;
  }

  .nav-link:focus,
  .nav-link:hover {
    color: #000;
  }

  .nav {
    display: block !important;
  }

  .dropdown-menu.show {
    transition: 0.4s;
    visibility: visible;
    opacity: 1;
  }

  .dropdown-menu {
    border: none !important;
    width: 100% !important;
    background: transparent !important;
    padding: 0px;
    visibility: hidden;
    opacity: 0;
    top: 43px !important;
    transition: 0.7s;
    display: block !important;
    inset: 0px !important;
    transform: translate(0px, 42px) !important;
  }

  .accordion-header {
    margin-top: 5px;
  }

  .accordion-item {
    border-bottom: 1px #ddd solid !important;
    border: none;
    border-radius: 0px !important;
  }

  .accordion-body {
    padding: 0px 0px 0px !important;
  }

  .accordion-button {
    color: #000000;
    opacity: 1;

    box-shadow: none;
    border-radius: 30px;
    font-size: 13px;
    padding: 9px 19px;
    font-weight: 300;
  }

  .accordion-button:focus {
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed) {
    color: #fff !important;
    background-color: #bccf31;
    box-shadow: none;
    border-radius: 30px !important;
  }

  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
  }

  .activeBtn {
    .dropdown-toggle {
      border-radius: 30px;
      background: #1b4e9b;
      position: relative !important;
      padding: 9px 19px !important;
      color: #fff !important;
      font-size: 15px;

      &::after {
        position: absolute;
        right: 17px;
        top: 18px;
        font-size: 26px;
      }
    }
  }
}

.commonClassDivSearch {
  margin-bottom: 10px;

  .form-control {
    border: none !important;
    padding: 7px 39px;
    background: #fff;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 300;

    -webkit-transition: width 0.5s;
    transition: width 0.5s;
  }

  .form-control:focus {
    box-shadow: none !important;
  }
}

.commonClassDivSearch1 {
  .studentWrapperCheck {
    position: relative;
    padding-left: 34px;
    margin-bottom: 17px;

    .studentWrapperDiv {
      position: absolute;
      left: 0;
      top: 0;
    }

    .studentProfileMainWrapper {
      position: relative;
      padding-left: 50px;
      padding-top: 10px;

      .studentProfileMainPos {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        overflow: hidden;
        width: 40px;
      }

      h2 {
        color: #000;
        line-height: 5px;
        font-weight: normal;
        margin-bottom: 0px;
        font-size: 15px;
      }

      i {
        color: #7c7777;
        font-style: normal;
        font-weight: normal;
        margin-bottom: 0px;
        font-size: 12px;
      }
    }
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  margin-bottom: 10px;

  .form-control {
    border: 1px #d3d3d3 solid !important;
    padding: 7px 39px;
    background: #fff;
    border-radius: 30px;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 300;

    -webkit-transition: width 0.5s;
    transition: width 0.5s;
  }

  .form-control:focus {
    box-shadow: none !important;
  }

  .form-check {
    margin-bottom: 20px;
    position: relative;
    padding-left: 30px;

    label {
      color: #696262;
    }
  }

  .form-check-input[type="checkbox"] {
    border-radius: 3px;
  }

  .form-check .form-check-input {
    border-radius: 3px;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    margin: 0px;
    top: 5px;
  }

  .form-check-input:checked {
    background: #1b4e9b;
    border: 1px #1b4e9b solid;
  }

  .form-check-input {
    border-radius: 3px;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    margin: 0px;
    top: 11px;
  }
}

.commonClassDivHeader {
  .form-control {
    border: none !important;
    padding: 7px 39px;
    background: #fff;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 300;
    width: 200px;
    margin-left: 11px;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
  }

  .form-control:focus {
    box-shadow: none !important;
    width: 250px;
  }
}

.LinkTo {
  background-color: #ffff;
  padding: 12px 30px !important;
  text-align: center;
  border-radius: 28px;
  font-size: 14px;
}

.slider1MainAll {
  .activeText {
    color: #1b4e9b;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background: #fff !important;
    border: 2px #1b4e9b solid !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;

    &::before {
      position: absolute;
      left: 60px;
      top: 32px;
      content: "";
      width: 100%;
      height: 2px;
      background: #1b4e9b;
      z-index: -2;
    }
  }

  .LastLink::before {
    display: none;
  }

  .nav-pills .nav-link {
    padding: 0px !important;
    border: 2px #ddd solid !important;
    background: #fff;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;

    &::before {
      position: absolute;
      left: 60px;
      top: 32px;
      content: "";
      width: 100%;
      height: 2px;
      background: #ddd;
      z-index: -2;
    }
  }

  .nav {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 19px 20px;
    margin-bottom: 10px;
  }

  .nav-item {
    position: relative;
    z-index: 10;
    // padding-right: 53px;
    // width: 127px;
  }
}

.accordionMainAll {
  h3 {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: block;
      padding-bottom: 7px;
      position: relative;
      padding-left: 21px;

      &:before {
        position: absolute;
        left: 0;
        top: 10px;
        content: "";
        width: 6px;
        border-radius: 50%;
        height: 6px;
        background-color: #3260a5;
      }
    }
  }

  .accordion-button::after {
    background-image: url("../public/assets/images/dropicon-png.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px !important;
  }

  .accordion-button {
    padding: 0px;
  }

  .accordion-item {
    border: none !important;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 19px 20px;
    margin-bottom: 10px;
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed) {
    background: none !important;
    color: #000;
    box-shadow: none;
  }

  .accordion-body {
    padding: 11px 0 0px;
    border-top: 1px #ddd solid;
    margin-top: 9px;
  }
}

.personalFieldDiv {
  .colMainSpace {
    padding: 0 5px !important;
  }

  .webTitlecolMainSpace {
    height: 100px;
  }

  .webDescriptioncolMainSpace,
  .webTiltlecolMainSpace {
    padding: 0 5px !important;
    width: 100%;

    .webTitleField {
      .form-label {
        font-size: 13px !important;
        font-weight: 600 !important;
        font-family: "Poppins", sans-serif !important;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: block;
      padding-bottom: 7px;
      position: relative;
      padding-left: 21px;
      // &:before {
      //   position: absolute;
      //   left: 0;
      //   top: 10px;
      //   content: "";
      //   width: 6px;
      //   border-radius: 50%;
      //   height: 6px;
      //   background-color: #3260a5;
      // }
    }
  }

  .btn-primary:focus {
    box-shadow: none !important;
  }

  span {
    font-size: 15px;
    font-weight: 700;
  }

  p {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #2c2c2c !important;
    margin-bottom: 5px;
    padding-left: 0px;
    text-align: left !important;
    line-height: 25px !important;
    margin: 5px 0px 10px !important;
  }

  .savebtnMain {
    background: #bccf31;
    border: 1px solid #bccf31;
    border-radius: 4px;
    padding: 10px 20px;

    height: 40px;
    font-weight: normal !important;
    color: #fff !important;
    font-weight: 500 !important;
    border: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 0px;

    @media screen and (max-width: 1199px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 570px) {
      width: 100%;
      padding: 10px 10px;
    }

    &:hover {
      background: #1b4e9b;
    }
  }

  .backbtnMain {
    background: #1b4e9b;
    border: 1px solid $sub-text;
    border-radius: 4px;
    padding: 10px 40px;

    height: 40px;
    margin-right: 5px;
    font-weight: normal !important;
    color: #fff !important;
    font-weight: 500 !important;
    border: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 0px;

    @media screen and (max-width: 1199px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 570px) {
      width: 100%;
      padding: 10px 10px;
    }

    &:hover {
      background: #bccf31;
    }
  }

  .nextbtnMain {
    background: #1b4e9b;
    border: 1px solid #1b4e9b;
    border-radius: 4px;
    padding: 10px 40px;

    height: 40px;
    font-weight: normal !important;
    color: #fff !important;
    font-weight: 500 !important;
    float: right;
    border: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 0px;

    &:hover {
      background: #bccf31;
    }

    @media screen and (max-width: 1199px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 570px) {
      width: 100%;
      padding: 10px 10px;
    }

    &:hover {
      background: #bccf31;
    }
  }

  .form-label {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #2c2c2c !important;
    margin-bottom: 5px;
  }

  .form-select {
    padding: 7px 8px;
    background-size: 12px 14px !important;
    opacity: 1;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #e2e2e2 !important;
    border-radius: 4px !important;
    color: #1c1919;
    width: 100% !important;
    display: block;
    padding-right: 33px !important;
  }

  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }

  .form-control:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }

  .input-group {
    display: block !important;
  }

  .form-control,
  .react-datepicker__input-container input {
    padding: 7px;
    background-size: 12px 14px !important;
    opacity: 1;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #e2e2e2 !important;
    border-radius: 4px !important;
    color: #1c1919;
    width: 100% !important;
    display: block;
  }

  .inputError,
  .react-datepicker__input-container .inputError {
    border: 1px solid red !important;
  }
}

.formPostionWrapper {
  position: relative;
  padding-left: 60px;

  .form-control {
    background: none !important;
    padding: 7px 5px;
    border-left: none !important;
    border-radius: 0px 6px 6px 0px !important;
  }

  .formPostionWrapperDiv {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 57px;

    &:before {
      position: absolute;
      right: 6px;
      top: 10px;
      content: "";
      width: 1px;
      height: 18px;
      background-color: #ddd;
      z-index: 10;
    }

    .form-select {
      border-right: none !important;
      padding: 8px 2px;
      border-radius: 6px 0px 0px 6px !important;
      background-size: 9px 9px !important;
      font-size: 12.5px;
    }
  }
}

.div .nav {
  overflow-x: auto !important;
  overflow-y: auto !important;
  flex-wrap: nowrap !important;
}

.mobileMenuMain {
  button {
    position: absolute;
    top: 0;
    left: 0;

    background: #fff !important;

    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
    font-size: 10px;
    width: 40px;
    padding: 10px;
    line-height: 0;
    border-radius: 0px;
  }
}

.tabBtnScroll {
  overflow-x: auto;
  display: flex;
  scrollbar-color: #1b4e9b #fff !important;
  scrollbar-width: thin;
  overflow-y: hidden;
}

// .tabBtnScroll::-webkit-scrollbar {
//   width: 8px;
// }
// .tabBtnScroll::-webkit-scrollbar-track {
//   background: #444;
// }
// .tabBtnScroll::-webkit-scrollbar-thumb {
//   background: #1b4e9b;
// }
// .tabBtnScroll::-moz-scrollbar {
//   width: 8px;
// }
// .tabBtnScroll::-moz-scrollbar-track {
//   background: #444;
// }
// .tabBtnScroll::-moz-scrollbar-thumb {
//   background: #1b4e9b;
// }
// .tabBtnScroll::-o-scrollbar {
//   width: 8px;
// }
// .tabBtnScroll::-o-scrollbar-track {
//   background: #444;
// }
// .tabBtnScroll::-o-scrollbar-thumb {
//   background: #1b4e9b;
// }
// .tabBtnScroll::-ms-scrollbar {
//   width: 8px;
// }
// .tabBtnScroll::-ms-scrollbar-track {
//   background: #444;
// }
// .tabBtnScroll::-ms-scrollbar-thumb {
//   background: #1b4e9b;
// }

.tabBtnScroll::-webkit-scrollbar {
  width: 1px;
}

.tabBtnScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.tabBtnScroll::-webkit-scrollbar-thumb {
  background: #1b4e9b;
  border-radius: 10px;
}

.tabBtnScroll::-webkit-scrollbar-thumb:hover {
  background: #1b4e9b;
}

.offcanvas-header {
  padding: 12px 16px 0px !important;
  position: relative;

  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.offcanvas-title {
  img {
    width: 130px !important;
  }
}

.inputError {
  border: 1px solid red !important;
}

.errorMessage {
  border: red;
  color: red;
  font-size: 11px;
  position: relative;
  top: 0px;
}

.form-field-new {
  position: relative;

  .form-text {
    position: absolute;
    right: 0px;
    font-size: 11px;
  }
}

.tabMainAllCommon {
  .StatusButton {
    border: none;
    color: #3c3c3c;
    font-size: 14px;
    font-weight: 400;
    width: 130px;
    border-radius: 20px;
    padding: 4px 10px;
  }

  .OpenColor {
    background-color: #c2fae2;
  }

  .ClosedColor {
    background-color: #ffcece;
  }

  .CompletedColor {
    background-color: #d5e4fa;
  }

  .ActiveColor {
    background-color: #d9f7ee;
  }

  .InActiveColor {
    background-color: #ffcece;
  }

  .table {
    .form-check-inline {
      margin-right: 0px;
    }

    .form-check-input:focus {
      box-shadow: none;
    }

    .form-check-input {
      border: 1px #707070 solid;
      width: 14px;
      height: 14px;
      border-radius: 3px;
    }

    background: #fff;

    th {
      background: #1b4e9b;
      color: #fff;
      border: none;
      vertical-align: middle;
      padding: 16px 10px;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
    }

    td {
      color: #3c3c3c;
      padding: 10px 10px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      vertical-align: middle;

      img {
        width: 5px;
      }

      .dropDownTable {
        .btn-success {
          background: none!important;
          box-shadow: none !important;
          padding: 0 !important;
          border: none !important;
        }

        .dropdown-toggle::after {
          display: none;
        }
      }
    }
  }
  .dropDownTable {
    .btn-success {
      background: none!important;
      box-shadow: none !important;
      padding: 0 !important;
      border: none !important;
    }

    .dropdown-toggle::after {
      display: none;
    }
  }

  .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  }

  .nav-tabs .nav-link {
    margin-bottom: 0px !important;
    border: none !important;
  }

  .nav-tabs {
    border-bottom: none;
    background: #fff;
    border-radius: 30px;
    margin-top: 10px;
  }

  .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #1b4e9b;
    border-radius: 30px;
    font-size: 14px;
    border: none;
  }

  .nav-link:focus {
    border: none;
  }

  .nav-link {
    border: none;
    margin-bottom: 0;
    color: #000;
    background-color: #fff;
    padding: 9px 20px;
    font-size: 14px;
    color: #000;

    &:hover {
      border-color: none !important;
      color: #000;
    }
  }
}

.paginationDisplay {
  display: flex;
  align-items: stretch;

  .displayFlexSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 5px 10px;
    margin: 0 4px 0px 0px;
    border-radius: 5px;
    border: 1px #b5afaf solid;

    h2 {
      position: relative;
      text-align: left;
      color: #1b4e9b;
      font-size: 11px;
      font-weight: 300;
      margin-bottom: 0px;
      margin-top: 0px;
      margin-right: 10px;
    }

    .form-select {
      width: 42px;
      border: solid 0.5px #b5afaf;
      height: 22px;
      border-radius: 2px;
      padding: 0 8px;
      font-weight: 300;
      background-repeat: no-repeat;
      background-position: right 1px center;
      background-size: 18px 8px;
      background-color: none;
      font-size: 11px;
      box-shadow: none !important;
      border-radius: 13px;
    }
  }

  .pagination {
    margin-bottom: 0px;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    font-size: 13px;
    background-color: #1b4e9b;
    height: 22px;
    border: 1px #1b4e9b solid !important;
  }

  .page-item {
    display: flex;
  }

  .page-link {
    list-style: none;
    display: flex;
    margin: 0 8px;

    font-size: 13px;
    height: 22px;
    color: #1b4e9b !important;

    .page-item:not(:first-child) .page-link {
      margin-left: 0px !important;
    }

    span {
      font-size: 23px;
      line-height: 15px;
    }
  }
}

.page-link:focus {
  z-index: 3;
  color: #fff !important;
  background-color: #1b4e9b;
  outline: 0 !important;
  box-shadow: none !important;
}

.queryTab {
  .nav-tabs .nav-link.active {
    color: #333;
    background-color: #fff !important;
    border-radius: 0;
    font-size: 14px;
    border-bottom: 3px #3260a5 solid !important;
  }

  .nav-tabs {
    border-bottom: none;
    background: #fff;
    border-radius: 4px;
    margin-top: 10px;
    padding: 0 17px;
  }
}

.formCheckMain {
  display: flex;
  align-items: stretch;
  margin-top: 39px;

  @media screen and (max-width: 992px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .form-label {
    padding-right: 20px;
    font-size: 12px !important;
  }

  .form-check-input[type="checkbox"] {
    border-radius: 1px;
  }
}

.workRules {
  ul {
    margin: 0 0 20px;
    padding: 0;

    li {
      display: block;
      padding-bottom: 7px;
      position: relative;
      padding-left: 21px;

      &::before {
        position: absolute;
        left: 0;
        top: 10px;
        content: "";
        width: 6px;
        border-radius: 50%;
        height: 6px;
        background-color: #3260a5;
      }
    }
  }
}

.nextbtnMainMainPluse {
  background: #1b4e9b;
  border: 1px solid #1b4e9b;
  padding: 0px !important;
  height: 30px;
  width: 30px;
  font-weight: normal !important;
  color: #fff !important;
  font-weight: 500 !important;
  float: right;
  border: none;
  margin-top: 20px;
  font-size: 14px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
}

.plusBtnMain {
  background: #bccf31 !important;
  border: 1px solid #bccf31 !important;
  padding: 0px !important;
  height: 30px;
  width: 30px;
  font-weight: normal !important;
  color: #fff !important;
  font-weight: 500 !important;
  float: right;
  margin-top: 20px;
  font-size: 14px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
}

.filterPopupMain {
  .nav-tabs {
    border-bottom: none !important;
    margin-top: 0;
    margin-bottom: 27px !important;
  }

  .nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #1b4e9b !important;
    border-color: 0px;
    border-radius: 30px;
  }

  .modal-header {
    border-bottom: none !important;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }

  .nav-tabs .nav-link {
    border: none;
    padding: 6px 26px;
    border-radius: 30px;
    color: #4b4a4a;
    font-weight: normal;
  }

  .modal-body {
    padding: 0px 40px 46px;
  }
}

.filterPopupMain {
  .modal-content {
    height: 580px;
    overflow: auto;
  }

  ul {
    margin: 30px 0 0;
    padding: 0;

    li {
      display: inline-block;
      padding-right: 10px;

      .applyBtnmain {
        background-color: #1b4e9b;
        border-radius: 30px;
        padding: 10px 46px;
        color: #fff;
        border: none;
        display: block;
        font-weight: normal;
        text-transform: uppercase;
        transition: 0.4s;

        &:hover {
          background-color: #bccf31;
        }
      }

      .saveBtnmain {
        background-color: #bccf31;
        border-radius: 30px;
        padding: 10px 46px;
        color: #fff;
        border: none;
        font-weight: normal;
        text-transform: uppercase;
        display: block;
        transition: 0.4s;

        &:hover {
          background-color: #1b4e9b;
        }
      }
    }
  }
}

.radioWrapper {
  position: relative;
  padding-left: 32px;
  margin-bottom: 20px;

  .radioDivPos {
    position: absolute;
    left: 0;
    top: -9px;
  }

  h3 {
    color: #696262;
    line-height: 5px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 14px;
  }

  i {
    color: #696262;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 0px;
    font-size: 12px;
  }
}

.datePickerInput {
  border-radius: 6px;
  border: solid 0.5px #d0d0d0;
  background: #fff url(../src/assets/images/date-icon.webp) no-repeat;
  color: #898686;
  font-weight: normal;
  background-position: 262px 4px;
  width: 300px;
  padding: 8px;
  line-height: 0px;
  font-size: 14px;
}

.askPopupBtn {
  position: fixed;
  right: -71px;
  bottom: 156px;
  transform: rotate(-90deg);
  background-color: #1b4e9b !important;
  font-size: 14px !important;
  box-shadow: none;
  color: #fff !important;
  border-radius: 18px 18px 0px 0px !important;
  padding: 5px 20px !important;
}

.btn-primary:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}

.queryPopupMain {
  .modal-content {
    overflow: auto;
    border-radius: 13px;
  }

  .modal-header {
    border-bottom: none !important;
  }

  .modal-body {
    padding: 0px 40px 46px;
  }

  h4 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    color: #333;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
  }

  .form-control {
    border: 1px #e6e6e6 solid;
    border-radius: 6px;
    font-size: 15px;
    padding: 6px 10px;
    box-shadow: none;
    margin-bottom: 5px;
  }

  p {
    font-size: 9px;
    color: #a8a8a8;
  }

  .submitAll {
    text-transform: uppercase;
    font-weight: 500;
    width: 100%;
    border-radius: 0px;
    background: #1b4e9b;
    padding: 10px;
    border: 1px #1b4e9b solid;
  }

  select {
    border: 1px #e6e6e6 solid;
    border-radius: 6px;
    font-size: 15px;
    padding: 6px 10px;
    box-shadow: none;
    margin-bottom: 5px;
  }

  .form-control:focus {
    box-shadow: none !important;
    border: 1px #e6e6e6 solid !important;
  }

  .form-select:focus {
    box-shadow: none !important;
    border: 1px #e6e6e6 solid !important;
  }

  ul {
    margin: 30px 0 0;
    padding: 0;

    li {
      display: inline-block;
      padding-right: 10px;

      .applyBtnmain {
        background-color: #1b4e9b;
        border-radius: 30px;
        padding: 10px 46px;
        color: #fff;
        border: none;
        display: block;
        font-weight: normal;
        text-transform: uppercase;
        transition: 0.4s;

        &:hover {
          background-color: #bccf31;
        }
      }

      .saveBtnmain {
        background-color: #bccf31;
        border-radius: 30px;
        padding: 10px 46px;
        color: #fff;
        border: none;
        font-weight: normal;
        text-transform: uppercase;
        display: block;
        transition: 0.4s;

        &:hover {
          background-color: #1b4e9b;
        }
      }
    }
  }
}

// .MainTabInsideLms {
//   .nav-link {
//     color: #000 !important;
//     background-color: #ffff;
//     padding: 13px 20px !important;
//     text-align: center;
//     font-size: 14px;
//     font-weight: 400;
//     border-radius: 28px !important;
//   }
//   .nav-link.active {
//     color: #fff !important;
//     background-color: #1b4e9b !important;
//     border-color: 0px;
//     border-radius: 28px;
//     padding: 13px 20px !important;
//   }
//   .css-19kzrtu {
//     padding: 0px !important;
//   }
//   .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
//     border-radius: 30px !important;
//     padding: 12px 22px !important;
//     border-bottom: 0px !important;
//     text-transform: capitalize !important;
//     font-weight: normal !important;
//     color: #fff !important;
//     background-color: #1b4e9b !important;
//   }
//   .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
//     text-transform: capitalize !important;
//     padding: 12px 22px !important;
//   }
//   .css-1aquho2-MuiTabs-indicator {
//     background: none !important;
//   }
//   .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
//     width: 22px !important;
//   }

// }

.AllcourseMain {
  .ImageCrd {
    position: relative;

    .overlay {
      border-radius: 8px;
      position: absolute !important;
      top: 0;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      opacity: 0.8;
      width: 100%;
    }

    .textOn {
      position: absolute;
      bottom: 0;
      padding: 0px 20px;

      p {
        font-size: 13px;
        color: #fff;
        font-weight: 200;
      }
    }
  }

  .card {
    margin-bottom: 30px;
    border-radius: 8px;
  }

  .card > * {
    flex-basis: 100%;
  }

  .card-body {
    padding: 0.8rem 1rem !important;
  }
}

.search {
  .form-control {
    border: none !important;
    padding: 10px 55px;
    background: #fff;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 300;
    width: 100%;
    padding-left: 50px;
    transition: width 0.5s;
  }
}

.form-control:focus {
  box-shadow: none !important;
}

.form-check-input[type="checkbox"] {
  border-radius: 0px !important;
}

.popover {
  border: 1px solid #1b4e9b;

  h5 {
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
  }
}

.AlumniGalleryPreviewModal {
  .modal-content {
    align-items: center !important;
  }
}

// resource preview style starts here

.ResourcePreviewMain {
  .placeholder {
    font-size: 12px;
    color: #7b7a7a;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 6px 13px;
    font-weight: 400;
    color: #4b4a4a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 13px;
  }

  .form-select:focus {
    box-shadow: none;
  }

  .form-check-input:focus {
    box-shadow: none !important;
  }
}

.modal-header {
  border-bottom: none !important;
}

.AlumniGalleryColumn {
  .card {
    margin: 15px 0px !important;
  }
}

.alumniGalleryFile {
  .modal-content {
    width: fit-content !important;
  }

  .applyBtn {
    margin: 15px auto 0px;
    display: block;
    border: solid 0.5px #e2e2e2;
    background-color: #1b4e9b;
    font-size: 14px;
    line-height: 0px;
    padding: 20px 0px;
    border-radius: 0px;
    width: 150px;
  }
}

.ResourceMain {
  .card {
    margin-bottom: 30px;
    width: 100%;
    max-width: 300px;
    border-radius: 0px;
  }

  .card > * {
    flex-basis: 100%;
  }
}

.dropFolder {
  .card {
    border: none !important;
  }

  .card-header {
    border: none !important;
    background-color: #fff !important;
    padding: 0rem 0rem !important;

    button {
      background-color: #fff !important;
      padding: 0rem 0rem !important;
      border: none;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .card-body {
    padding: 1rem 0rem !important;
  }
}

.slideMainAll {
  .nav .active {
    background-color: #bccf31 !important;
    z-index: 1;
    text-align: center !important;
    display: inline-block;
    color: #fff !important;
  }

  .leftButtonActive {
    background-color: #bccf31 !important;
    color: #fff;
    z-index: 1;
    text-align: center !important;
    display: inline-block;

    &:hover {
      color: #ffff;
    }
  }

  .leftButton {
    color: #000 !important;
    text-align: center !important;
    background-color: #ffff !important;
    border: none;
    font-size: 14px;
    display: inline-block;
    position: relative;
  }

  .ActiveLeft {
    position: relative;
    margin-left: -20px;
  }

  .nav-link {
    padding: 10px 30px !important;
    text-align: center;
    border-radius: 28px !important;
  }

  .nav-link.active {
    padding: 10px 30px !important;
  }
}

.custom-dialog-action {
  position: absolute;
  top: 50%;
  left: 400px;

  .modal-content {
    max-width: 152px;
    max-height: 100px;

    .commonPopupmainAction {
      padding: 10px 20px;

      p {
        font-size: 14px;
        font-weight: 400;
        padding: 10px 0;
        margin-bottom: 0rem !important;
        margin: 0px !important;
        cursor: pointer;
      }

      .border-action {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
}

.DotImg {
  float: right;
  display: flex;
  cursor: pointer;

  .ImgEdit {
    width: 45px;
    object-fit: contain;
  }
}

.ImageNone {
  display: none;
}

.LiveClassMain {
  .react-calendar {
    border: none !important;
    padding: 15px;
    width: 100% !important;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #212020 !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #212020 !important;
  }

  .react-calendar__tile--now {
    background: #bccf31 !important;
    color: #fff !important;
    border-radius: 20px;

    &:hover {
      background: #bccf31;
    }
  }

  .react-calendar__tile--active {
    background: #1b4e9b !important;
    color: white !important;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 12px;
  }

  .react-calendar__tile--active:focus {
    background: #1b4e9b !important;
    color: white !important;
    border-radius: 20px;
  }

  .react-calendar__tile:enabled:focus {
    background: #1b4e9b !important;
    color: #fff !important;
    border-radius: 20px;
  }

  .react-calendar__tile:enabled:hover {
    border-radius: 20px;
  }

  abbr[data-bs-original-title],
  abbr[title] {
    text-decoration: none !important;
    cursor: unset;
    text-decoration-skip-ink: none;
  }

  .react-calendar__navigation__prev2-button {
    display: none !important;
  }

  .react-calendar__navigation__next2-button {
    display: none !important;
  }

  .react-calendar__navigation__label {
    float: left !important;
    font-family: "Poppins", sans-serif;
  }

  .react-calendar__navigation__label:hover {
    background: none !important;
  }

  .react-calendar__navigation__label:focus {
    background: none !important;
  }

  .react-calendar__month-view__weekdays {
    border-bottom: 1px solid #1b4e9b;
    margin-bottom: 13px !important;
    font-weight: 500 !important;
    font-family: "Poppins", sans-serif;
  }

  .react-calendar__navigation__label__labelText {
    color: #1b4e9b;
    font-weight: 500;
  }

  .react-calendar__navigation {
    display: flex !important;
    margin-bottom: 0em;
  }

  .react-calendar__navigation__label:focus {
    background: none;
  }

  .react-calendar__navigation button {
    min-width: unset !important;
    background: none !important;
  }

  .react-calendar__navigation__prev-button {
    font-family: "Roboto", sans-serif;

    &:hover,
    :focus {
      background: none !important;
    }
  }

  .react-calendar__navigation__next-button {
    font-family: "Roboto", sans-serif;

    &:hover,
    :focus {
      background: none !important;
    }
  }
}

.custom-dialog-addclass {
  border-radius: 5px !important;

  .LiveClassMain {
    font-family: "Roboto", sans-serif;
  }

  input::placeholder {
    font-size: 14px !important;
  }

  .form-group {
    margin-bottom: 10px;
  }
}

.joinMeetingBtnBlue {
  background: #1b4e9b !important;
  padding: 5px 20px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: none !important;

  &:hover {
    background: #e2e3e5 !important;
    color: #333 !important;
  }
}

.joinMeetingBtnGrey {
  background: #1b4e9b !important;
  padding: 5px 20px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: none !important;

  &:hover {
    background: #e2e3e5 !important;
  }
}

.newsFeedWrapperField {
  background: #fff;
  position: relative;
  border-radius: 2px;
  margin-bottom: 10px;

  input {
    border: none !important;
    color: #969696;
    font-size: 14px;
    border-radius: 3px;
    padding: 24px 79px;
    box-shadow: none !important;
  }

  img {
    position: absolute;
    left: 27px;
    top: 16px;
    width: 39px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
}

.newfeedCommentBoxField {
  .form-control {
    border: none !important;
    color: #969696;
    font-size: 14px;
    padding: 13px 58px 13px 26px;
    box-shadow: none !important;
    border-radius: 30px;
    background: #f0f5fb !important;
  }
}

.newfeedCommentBoxField {
  .messageCommetBox1 {
    .form-control {
      border: none !important;
      color: #969696;
      font-size: 14px;
      padding: 13px 58px 13px 26px;
      box-shadow: none !important;
      border-radius: 30px;
      background: #fff !important;
    }
  }
}

.mainStickyBorderFlex {
  display: flex;
  justify-content: space-between;

  .form-select {
    width: auto !important;
    padding: 0px !important;
    font-size: 14px;
    border: none;
    box-shadow: none;
    color: #a4a1a1 !important;
    padding-right: 14px !important;
    background-position: right !important;
    background-size: 12px 18px !important;
    font-weight: 500;
  }

  .form-select:focus {
    box-shadow: none;
  }

  .form-check-label {
    font-size: 13px;
    color: #000;
    font-weight: normal;
  }

  .form-check-input[type="checkbox"] {
    border-radius: 0px !important;
    width: 16px;
    height: 16px;
    border: solid 1px #707070;
  }

  .form-check-input:focus {
    box-shadow: none !important;
  }

  .form-check {
    padding-left: 24px !important;
    margin-bottom: 0px;
    position: relative;
  }

  .form-check .form-check-input {
    margin-left: 0px;
    margin-top: 0px;
    position: absolute;
    left: 0;
    top: 5px;
  }
}

.checkBoxDivFlex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.formCheckMainAll {
  position: relative;
  padding-left: 27px;

  .attendenceWrapper {
    margin-bottom: 0px !important;
  }

  .form-check-input {
    margin-left: 0px;
    margin-top: 0px;
    position: absolute;
    left: 0;
    top: 12px;
  }

  .form-check-input:focus {
    box-shadow: none !important;
  }

  .form-check-input[type="checkbox"] {
    border-radius: 0px !important;
    width: 16px;
    height: 16px;
    border: solid 1px #707070;
  }
}

.attendenceCalender {
  .rbc-month-view {
    background: #fff;
    border: none;
  }

  .rbc-month-header {
    padding: 10px 14px 10px;
    background: #1b4e9b !important;
    color: #fff;
  }

  .rbc-header {
    border-bottom: none;
    font-size: 16px;
    font-weight: normal;
  }

  .rbc-header + .rbc-header {
    border-left: none;
  }

  .rbc-toolbar {
    margin-bottom: 0;
    font-size: 16px;
    background: #225fbb !important;
  }

  .rbc-toolbar button {
    border: none;
    color: #fff;
  }

  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    box-shadow: none;
    background: none;
  }

  .rbc-toolbar-label {
    color: #fff;
    padding: 14px 7px;
    text-align: center;
    font-size: 17px;
    display: block;
    font-weight: bold;
  }

  .rbc-today {
    background-color: #1b4e9b0f;
  }

  .rbc-toolbar button:focus {
    background: none !important;
  }

  .rbc-off-range-bg {
    background: #fefff4;
  }

  .rbc-button-link {
    font-size: 12px;
    font-weight: 300;
    width: 97%;
    margin: 0 auto;
  }

  .rbc-toolbar button:hover {
    background: #1b4e9b !important;
    border-color: #1b4e9b !important;
  }

  .rbc-day-slot .rbc-event-content {
    line-height: 14px !important;
  }

  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border: 1px #1b4e9b solid;
    box-sizing: border-box;
    box-shadow: none !important;
    margin: 0;
    background: #1b4e9b;
    border: 1px solid white !important;
    padding: 3px 5px !important;
    color: #fff;
    cursor: pointer;
    outline: none;
    width: 100%;
    text-align: left;
    font-size: 11px;
  }

  .rbc-ellipsis,
  .rbc-show-more,
  .rbc-row-segment .rbc-event-content,
  .rbc-event-label {
    display: block;
  }

  .rbc-event.rbc-selected,
  .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: none !important;
  }

  .rbc-toolbar button:active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus {
    background: #1b4e9b !important;
    color: #fff !important;
  }

  .rbc-time-gutter {
    color: #fff;

    font-size: 14px;
    font-weight: 500;

    .rbc-timeslot-group {
      background: #225fbb;
    }
  }

  .rbc-day-slot {
    .rbc-timeslot-group {
      background: #fff;
    }
  }
}

.ScheduleCalender .rbc-event {
  min-height: 5em !important;
}

.MainTabInsideLms {
  .nav-link {
    cursor: pointer;
    color: #000 !important;
    background-color: #ffff;
    padding: 13px 20px !important;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border-radius: 28px !important;
  }

  .nav-link.active {
    color: #fff !important;
    background-color: #1b4e9b !important;
    border-color: 0px;
    border-radius: 28px;
    padding: 13px 20px !important;
  }

  .css-19kzrtu {
    padding: 0px !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    border-radius: 30px !important;
    padding: 12px 22px !important;
    border-bottom: 0px !important;
    text-transform: capitalize !important;
    font-weight: normal !important;
    color: #fff !important;
    background-color: #1b4e9b !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
    padding: 12px 22px !important;
  }

  .css-1aquho2-MuiTabs-indicator {
    background: none !important;
  }

  .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
    width: 22px !important;
  }
}

.forumMain {
  .form-control:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }

  .accordion-item {
    border: none;
    margin-top: 10px;
    border-radius: 0px !important;
  }

  .accordion-body {
    padding: 10px 0px !important;
    border-top: 1px #ddd solid !important;

    .form-control {
      border: none !important;
      background: #f0f5fb;
      border-radius: 30px;
      font-weight: 300;
      width: 100%;
      box-shadow: none !important;
      color: #969696;
      font-size: 14px;
      padding: 13px 58px 13px 26px;
    }

    .placeholder {
      font-size: 13px;
    }
  }

  .accordion-button {
    color: #000000;
    padding: 7px 0px !important;
    opacity: 1;
    box-shadow: none;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
    border-top: 1px #ddd solid !important;
    position: relative;

    &::after {
      display: none;
    }

    .commentsForum {
      display: flex;
      position: absolute;
      right: 0;

      img {
        margin-right: 10px;
        width: 17px;
        object-fit: contain;
      }

      p {
        margin-bottom: 0 !important;
      }
    }

    .accordion-button:focus {
      box-shadow: none !important;
    }

    .accordion-button:first-child {
      border-top: none;
    }

    .accordion-item:first-of-type .accordion-button {
      border-top: none !important;
    }

    .activeBtn {
      .dropdown-toggle {
        border-radius: 0px;
      }
    }
  }

  .accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #ffff !important;
    box-shadow: none;
    border-radius: 0px !important;
    position: relative;

    &::after {
      position: absolute;
      right: 0px;
      transform: rotate(0deg) !important;
      background-size: 17px !important;
    }
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    box-shadow: none !important;
  }
}

.AllcourseMain {
  .ImageCrd {
    position: relative;

    .overlay {
      border-radius: 8px;
      position: absolute !important;
      top: 0;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      opacity: 0.8;
      width: 100%;
    }

    .textOn {
      position: absolute;
      bottom: 0;
      padding: 0px 20px;

      p {
        font-size: 13px;
        color: #fff;
        font-weight: 200;
      }
    }
  }

  .card {
    margin-bottom: 30px;
    border-radius: 8px;
  }

  .card > * {
    flex-basis: 100%;
  }

  .card-body {
    padding: 0.8rem 1rem !important;
  }
}

.search {
  position: relative;

  .clearClose {
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: 100;
    cursor: pointer;

    img {
      width: 18px;
    }
  }

  .form-control {
    border: none !important;
    padding: 10px 55px;
    background: #fff !important;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 300;
    width: 100%;
    padding-left: 50px;
    transition: width 0.5s;
  }
}

.form-control:focus {
  box-shadow: none !important;
}

.form-check-input[type="checkbox"] {
  border-radius: 0px !important;
}

.popover {
  border: 1px solid #1b4e9b;

  h5 {
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
  }
}

.modal-header {
  border-bottom: none !important;
}

.dropFolder {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  // .card {
  //   border: none !important;
  // }
  // .card-header {
  //   border: none !important;
  //   background-color: #fff !important;
  //   padding: 0rem 0rem !important;
  //   button {
  //     background-color: #fff !important;
  //     padding: 0rem 0rem !important;
  //     border: none;

  //   }
  // }
  // .card-body {
  //   padding: 1rem 0rem !important;
  // }
}

.slideMainAll {
  .nav .active {
    background-color: #bccf31 !important;
    z-index: 1;
    text-align: center !important;
    display: inline-block;
    color: #fff !important;
  }

  .leftButtonActive {
    background-color: #bccf31 !important;
    color: #fff;
    z-index: 1;
    text-align: center !important;
    display: inline-block;

    &:hover {
      color: #ffff;
    }
  }

  .leftButton {
    color: #000 !important;
    text-align: center !important;
    background-color: #ffff;
    border: 1px solid #e5e5e5;
    font-size: 14px;
    display: inline-block;
    position: relative;
  }

  .ActiveLeft {
    position: relative;
    margin-left: -20px;
  }

  .nav-link {
    padding: 10px 30px !important;
    text-align: center;
  }

  .nav-link.active {
    padding: 10px 30px !important;
  }
}

.custom-dialog-action {
  position: absolute;
  top: 50%;
  left: 400px;

  .modal-content {
    max-width: 152px;
    max-height: 100px;

    .commonPopupmainAction {
      padding: 10px 20px;

      p {
        font-size: 14px;
        font-weight: 400;
        padding: 10px 0;
        margin-bottom: 0rem !important;
        margin: 0px !important;
        cursor: pointer;
      }

      .border-action {
        border-bottom: 1px solid #7c7777;
      }
    }
  }
}

::-webkit-file-upload-button {
  color: #fff !important;
  background-color: #ffff !important;
  border: 1px white !important;
  border-radius: 2px !important;
}

.attendencePopupMain {
  .form-check-input:active {
    filter: none;
  }

  .modal-body {
    padding: 20px 30px 35px;
  }

  .form-label {
    margin-bottom: 3px;
    font-size: 12px;
    color: #2c2c2c;
    font-weight: normal;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 6px 13px;

    font-weight: 400;

    color: #c5c5c5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 13px;
  }

  .colMainSpace {
    padding: 0 3px;
  }

  p {
    color: #ff4343;
    font-size: 10px;
    margin-bottom: 0px;
    position: relative;
    top: -7px;
  }

  .applyBtn {
    margin: 15px auto 0px;
    display: block;
    border: solid 0.5px #e2e2e2;
    background-color: #1b4e9b;
    font-size: 14px;
    line-height: 0px;
    padding: 20px 0px;
    border-radius: 0px;
    width: 150px;
  }

  .mb-3 {
    margin-bottom: 10px !important;
  }

  .form-check-input {
    border: solid 2px #acacac;
    width: 17px;
    height: 17px;
    appearance: auto;
  }

  .form-check-label {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
  }

  .form-check-input:checked {
    background-color: #1b4e9b;
    border-color: #1b4e9b;
  }

  .form-check {
    position: relative;
  }

  .form-check .form-check-input {
    float: none;
    margin-left: 0px;
    position: absolute;
    left: 0;
    top: 6px;
    margin-top: 0;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .modal-header {
    background: #1b4e9b !important;

    .btn-close {
      top: -30px !important;
      right: -46px !important;
    }

    // .btn-close {
    //   color: #fff;
    //   opacity: 1;
    //   outline: none;
    //   background-image: url("../public/assets/images/close-btn.webp");
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   background-size: 14px !important;
    // }
    // .btn-close:focus {
    //   box-shadow: none;
    // }
  }

  .modal-title {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }
}

.messageCommetBox {
  position: relative;

  button {
    position: absolute;
    right: 9px;
    top: 5px;
    border: none;
    background: none !important;
    padding: 0px;

    &:hover {
      background: none !important;
    }
  }
}

.popover-arrow {
  position: absolute;
  top: 31px !important;
  display: none !important;
  transform: translate(0px);
}

.popover-body {
  padding: 0px !important;

  button {
    display: block;
    border: none !important;
    background: none !important;
    padding-bottom: 5px;
    border-radius: 0px;
    width: 100% !important;
    text-align: left !important;
    color: #000 !important;
    border-bottom: 1px #ddd solid !important;

    &:last-of-type {
      border-bottom: none !important;
    }
  }

  .sideDivChatMainWrapper {
    position: relative;
    border-bottom: 1px #c6c6c6 solid;
    min-height: 66px;
    padding: 10px 31px 10px 64px;
    transition: 0.4s;
    z-index: 100;

    &:hover {
      background: #f8f8f8;
    }

    i {
      position: absolute;
      right: 6px;
      bottom: 8px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      font-style: normal;
      background: #1b4e9b;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      z-index: -1;
    }

    p {
      white-space: nowrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #808080;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0px;
      height: 18px;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 4px;
          color: #181818;
        }

        span {
          color: #808080;
          font-size: 11px;
          z-index: 1;
          font-weight: 400;
        }
      }
    }

    .sideDivChatMainPos {
      position: absolute;
      left: 0px;
      top: 8px;
      width: 100%;

      .sideDivChatMainPosImg {
        overflow: hidden;
        width: 44px;
        border-radius: 50%;
        margin-left: 11px;

        img {
          border-radius: 50%;
          width: 44px;
          height: 44px;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

// live class popup start here

.LiveClassPopupMain {
  .LiveclassPopWidth {
    width: 100%;
    max-width: 700px;
  }

  .form-check-input:active {
    filter: none;
  }

  .modal-body {
    padding: 20px 30px 35px;
  }

  .form-label {
    margin-bottom: 3px;
    font-size: 12px;
    color: #2c2c2c;
    font-weight: normal;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 6px 13px;
    font-weight: 400;
    color: #c5c5c5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 13px;
  }

  .colMainSpace {
    padding: 0 3px !important;
  }

  p {
    color: #ff4343;
    font-size: 10px;
    margin-bottom: 0px;
    position: relative;
    top: -7px;
  }

  .applyBtn {
    margin: 15px auto 0px;
    display: block;
    border: solid 0.5px #e2e2e2;
    background-color: #1b4e9b;
    font-size: 14px;
    line-height: 0px;
    padding: 20px 0px;
    border-radius: 0px;
    width: 150px;
  }

  .mb-3 {
    margin-bottom: 10px !important;
  }

  .form-check-input {
    border: solid 2px #acacac;
    width: 17px;
    height: 17px;
    border-radius: 0px;
    appearance: auto;
  }

  .form-check-label {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
  }

  .form-check-input:checked {
    background-color: #1b4e9b;
    border-color: #1b4e9b;
  }

  .form-check {
    margin-right: 10px;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .modal-header {
    position: relative;
    background: #1b4e9b !important;

    .btn-close {
      position: absolute;
      top: -18px;
      right: -25px;
      color: #fff;
      opacity: 1;
      outline: none;
      background-image: url("../public/assets/images/close.svg");
      background-repeat: no-repeat;
      background-position: contain;
      background-size: 39px !important;
    }

    .btn-close:focus {
      box-shadow: none;
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      right: -33px;
      top: -26px;

      img {
        width: 37px;
        object-fit: contain;
      }
    }
  }

  .modal-title {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }

  .css-1hb7zxy-IndicatorsContainer {
    background: #ffff !important;
  }
}

// live class popup ends here

.newLargeFormPopup {
  .LiveclassPopWidth {
    width: 100%;
    max-width: 700px;
  }

  .form-check-input:active {
    filter: none;
  }

  .modal-body {
    padding: 20px 30px 35px;
  }

  .form-label {
    margin-bottom: 3px;
    font-size: 12px;
    color: #2c2c2c;
    font-weight: normal;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 6px 13px;
    font-weight: 400;
    color: #c5c5c5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 13px;
  }

  .colMainSpace {
    padding: 0 3px !important;
  }

  p {
    color: #ff4343;
    font-size: 10px;
    margin-bottom: 0px;
    position: relative;
    top: -7px;
  }

  .applyBtn {
    margin: 15px auto 0px;
    display: block;
    border: solid 0.5px #e2e2e2;
    background-color: #1b4e9b;
    font-size: 14px;
    line-height: 0px;
    padding: 20px 0px;
    border-radius: 0px;
    width: 150px;
  }

  .mb-3 {
    margin-bottom: 10px !important;
  }

  .form-check-input {
    border: solid 2px #acacac;
    width: 17px;
    height: 17px;
    border-radius: 0px;
    appearance: auto;
  }

  .form-check-label {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
  }

  .form-check-input:checked {
    background-color: #1b4e9b;
    border-color: #1b4e9b;
  }

  .form-check {
    margin-right: 10px;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .modal-header {
    position: relative;
    background: #fff !important;

    .btn-close {
      position: absolute !important;
      top: -52px !important;
      right: -86px !important;
      color: #fff;
      opacity: 1;
      outline: none;
      background-image: url("../public/assets/images/close.svg");
      background-repeat: no-repeat;
      background-position: contain;
      background-size: 39px !important;
    }

    .btn-close:focus {
      box-shadow: none;
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      right: -33px;
      top: -26px;

      img {
        width: 37px;
        object-fit: contain;
      }
    }
  }

  .modal-title {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }

  .css-1hb7zxy-IndicatorsContainer {
    background: #ffff !important;
  }
}

// comment section in fourm starts here
.messageCommetBoxForum {
  position: relative;

  .BgInput {
    background-color: #fff !important;
    margin-top: 10px;
    margin-left: -4px !important;
  }

  .bgNew {
    background-color: #f0f5fb !important;
  }

  button {
    position: absolute;
    right: 9px;
    top: 6px;
    border: none;
    background: none !important;
    padding: 0px;

    &:hover {
      background: none !important;
    }

    img {
      width: 35px;
      object-fit: contain;
    }
  }
}

.colSpaceGroup {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.colSpaceGrades {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

// Groups style starts hereL
.GroupMainSec {
  margin-bottom: 30px;

  .filter {
    cursor: pointer;

    .filterLabel {
      border: none;
      padding: 10px 10px;
      background: #fff;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      color: #000;
      padding-left: 45px;
    }
  }
}

// Group manual popup style starts here
.GroupPopupMain {
  .modal-dialog {
    max-width: 75%;
  }

  .BorderManual {
    border-right: 1px solid #f0ebeb87;
  }

  .search {
    .form-control {
      border: 1px solid #e0e0e0;
      padding: 8px 35px;
      background: #fff !important;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 300;
      width: 100%;
      padding-left: 50px;
      transition: width 0.5s;
    }
  }

  .modal-group {
    width: 100%;
    max-width: 680px;

    .btn-close {
      top: -32px !important;
      right: -52px !important;
    }
  }

  .modal-profile {
    width: 100%;
    max-width: 920px;

    .btn-close {
      top: -32px !important;
      right: -52px !important;
    }
  }

  .modal-profile-id-card {
    width: 100%;
    max-width: 1050px;

    .btn-close {
      top: -32px !important;
      right: -52px !important;
    }
  }

  .modal-leave {
    width: 100%;
    max-width: 780px;

    .btn-close {
      top: -32px !important;
      right: -52px !important;
    }
  }

  .modal-grades {
    width: 100%;
    max-width: 680px;
  }

  .modal-body {
    padding: 0px 30px 30px;
  }

  .form-label {
    margin-bottom: 3px;
    font-size: 12px;
    color: #2c2c2c;
    font-weight: normal;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 6px 13px;
    font-weight: 400;
    color: #000 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 13px;
  }

  .colMainSpace {
    padding: 0 3px;
  }

  .mb-3 {
    margin-bottom: 10px !important;
  }

  .modal-header {
    padding-top: 25px;
    padding-bottom: 10px;
    padding-left: 0;

    .btn-close {
      position: absolute;
      top: -18px;
      right: -25px;
      color: #fff;
      opacity: 1;
      outline: none;
      background-image: url("../public/assets/images/close.svg");
      background-repeat: no-repeat;
      background-position: contain;
      background-size: 40px !important;
    }

    .btn-close:focus {
      box-shadow: none;
    }
  }

  .modal-title {
    font-size: 16px;
    color: #4b4a4a;
    font-weight: 600;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 6px 13px;
    font-weight: 400;
    color: #4b4a4a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 13px;

    option {
      color: #000;
    }

    option:first-child {
      color: #9f9f9f;
    }
  }

  .form-select:focus {
    box-shadow: none !important;
  }

  .applyBtn {
    margin: 15px auto 0px;
    display: block;
    border: solid 0.5px #e2e2e2;
    background-color: #1b4e9b;
    font-size: 14px;
    line-height: 0px;
    padding: 20px 0px;
    border-radius: 0px;
    width: 150px;
  }
}

// Group Auto popup style starts here

.AutoPopupMain {
  .modal-group-auto {
    width: 100%;
    max-width: 350px;
  }

  .modal-assignment {
    width: 100%;
    max-width: 400px !important;
  }

  .modal-program {
    width: 100%;
    max-width: 450px !important;
  }

  .modal-body {
    padding: 0px 40px 35px;
  }

  .form-label {
    margin-bottom: 3px;
    font-size: 12px;
    color: #2c2c2c;
    font-weight: normal;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 6px 13px;
    font-weight: 400;
    color: #c5c5c5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 13px;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 6px 13px;
    font-weight: 400;
    color: #4b4a4a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 13px;
  }

  .form-select:focus {
    box-shadow: none !important;
  }

  .colMainSpace {
    padding: 0 3px !important;
  }

  p {
    color: #ff4343;
    font-size: 10px;
    margin-bottom: 0px;
    position: relative;
    top: 0px;
  }

  .applyBtn {
    display: block;
    border: solid 0.5px #e2e2e2;
    background-color: #1b4e9b;
    font-size: 14px;
    line-height: 0px;
    width: 100%;
    padding: 20px 0px;
    border-radius: 0px;
    margin-top: 15px;
  }

  .mb-3 {
    margin-bottom: 10px !important;
  }

  .modal-header {
    position: relative;
    padding-top: 25px;
    padding-bottom: 10px;
    padding-left: 0;

    .btn-close {
      position: absolute !important;
      top: -36px !important;
      right: -77px !important;
      color: #fff;
      opacity: 1;
      outline: none;
      background-image: url("../public/assets/images/close.svg");
      background-repeat: no-repeat;
      background-position: contain;
      background-size: 39px !important;
    }

    .btn-close:focus {
      box-shadow: none;
    }
  }

  .modal-title {
    font-size: 16px;
    color: #4b4a4a;
    font-weight: 600;
  }

  .form-check-input {
    border: solid 2px #acacac;
    width: 17px;
    height: 17px;
    appearance: auto;
  }

  .form-check-label {
    display: inline-block;
    margin-bottom: 3px;
    font-size: 12px;
    color: #2c2c2c;
    font-weight: normal;
  }

  .form-check-input:checked {
    background-color: #1b4e9b;
    border-color: #1b4e9b;
  }

  .form-check {
    position: relative;
  }

  .form-check .form-check-input {
    float: none;
    margin-left: 0px;
    position: absolute;
    left: 0;
    top: 6px;
    margin-top: 0;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .form-check-input:active {
    filter: none;
  }
}

// feedback style starts here
.FeedbackMainSec {
  margin-bottom: 30px;

  .search {
    .form-control {
      border: 1px solid #e0e0e0;
      padding: 10px 55px;
      background: #fff !important;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 300;
      width: 100%;
      padding-left: 50px;
      transition: width 0.5s;
    }
  }
}

// feedbackform style starts here
.FeedBackFromMainSec {
  .form-label {
    margin-bottom: 10px;
    font-size: 12px;
    color: #2c2c2c;
    font-weight: normal;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 7px 13px;
    font-weight: 400;
    color: #000 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 13px;
  }

  .submitfeedback {
    float: right;
    margin-top: 15px;

    .applyBtn {
      display: block;
      border: solid 0.5px #e2e2e2;
      background-color: #1b4e9b;
      font-size: 14px;
      line-height: 0px;
      padding: 20px 0px;
      border-radius: 5px;
      width: 180px;
    }
  }
}

// profile style main
.ProfileFlexMain {
  display: flex;
  align-items: center;

  img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 22px !important;
    object-fit: cover !important;
    margin-right: 15px !important;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  p {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0px;
    color: #746f6f;
  }
}

//  people style starts here

.PeopleMainDiv {
  .infinite-scroll-component {
    overflow: unset !important;
  }

  .card {
    border: none !important;
    margin-bottom: 20px;
    border-radius: 0px;
  }

  .ProfileFlexMain {
    align-items: flex-start;
  }
}

.PeopleTab {
  .search {
    .form-control {
      border: 1px solid #e0e0e0;
      padding: 10px 55px;
      background: #fff !important;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 300;
      width: 340px;
      padding-left: 50px;
      transition: width 0.5s;
    }

    .searchWrapperMain {
      position: relative;
      border-radius: 30px;

      .searchWrapperMainDiv {
        position: absolute;
        left: 6px;
        top: 4px;
        z-index: 100;

        img {
          width: 33px;
        }
      }
    }
  }

  .form-select {
    padding: 7px 8px;
    background-size: 12px 14px !important;
    opacity: 1;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px !important;
    color: #1c1919;
    width: 240px !important;
    display: block;
  }

  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }
}

.PeoplePopup {
  .modal-people {
    width: 100%;
    max-width: 790px;

    .modal-body {
      padding: 0px 30px 30px;
    }

    .modal-header {
      padding-top: 25px;
      padding-bottom: 10px;
      padding-left: 0;

      .btn-close {
        position: absolute;
        top: -30px !important;
        right: -46px !important;
        color: #fff;
        opacity: 1;
        outline: none;
        background-image: url("../public/assets/images/close.svg");
        background-repeat: no-repeat;
        background-position: contain;
        background-size: 40px !important;
      }

      .btn-close:focus {
        box-shadow: none;
      }
    }
  }
}

.zoomButtonStyle {
  display: block;
  margin: 0 auto;
  background-color: #1b4e9b;
  border-radius: 5px;
  padding: 7px 30px;
  color: #fff;
  border: none;
  font-weight: normal;
  transition: 0.4s;

  &:hover {
    background-color: #bccf31;
  }
}

.commentNameDiv {
  .accordion-button {
    justify-content: end;
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;

    &::after {
      display: none !important;
    }
  }

  .accordion-button:not(.collapsed) {
    background: none !important;
  }

  .accordion-item {
    border: none !important;
  }

  .accordion-body {
    padding: 0px !important;
  }
}

.LiveClassMain1 {
  .react-calendar {
    padding: 0px !important;
  }

  .react-calendar__tile {
    font-size: 13px !important;
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 5px !important;
  }
}

.calenderMainClass {
  h2 {
    color: #000;
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 11px;
  }
}

.read-or-hide {
  cursor: pointer;
  font-weight: 600;
}

// common table style starts here
.tabMainAllGroup {
  margin-bottom: 0px;
  margin-top: 10px;
  padding-bottom: 50px;

  .table-responsive {
    border-radius: 10px;
  }

  .StatusButton {
    border: none;
    color: #3c3c3c;
    font-size: 14px;
    font-weight: 400;
    width: 130px;
    border-radius: 20px;
    padding: 4px 10px;
  }

  .OpenColor {
    background-color: #c2fae2;
  }

  .ClosedColor {
    background-color: #ffcece;
  }

  .CompletedColor {
    background-color: #d5e4fa;
  }

  .ActiveColor {
    background-color: #d9f7ee;
  }

  .InActiveColor {
    background-color: #ffcece;
  }

  .table {
    background: #fff;

    th {
      background: #1b4e9b;
      color: #fff;
      border: none;
      vertical-align: middle;
      font-family: "Poppins", sans-serif;
      padding: 16px 20px;
      font-weight: 500;
      font-size: 14px;
      text-align: left;
    }

    td {
      color: #3c3c3c;
      padding: 10px 15px;
      font-family: "Poppins", sans-serif;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      vertical-align: middle;
    }
  }

  .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  }
}

// common popup style starts here

// assignment style starts her

.AssignmentMain {
  .AssignmentSub {
    .form-label {
      margin-bottom: 3px;
      font-size: 13px;
      color: #2c2c2c;
      font-weight: normal;
    }

    .form-control {
      display: block;
      width: 100%;
      padding: 10px 13px;
      font-weight: 400;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 3px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      font-size: 13px;
    }
  }

  .filter {
    cursor: pointer;

    .filterLabel {
      border: none;
      padding: 10px 10px;
      background: #fff;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      color: #000;
      padding-left: 45px;
    }
  }
}

.inactiveBtnmain {
  background: #ffcece;
  border-radius: 30px;
  padding: 2px 28px;
  color: #3c3c3c;
  font-size: 13px;
  font-weight: normal;
  width: 100%;
}

.activeBtnmain {
  background: #c2fae2;
  border-radius: 30px;
  padding: 2px 28px;
  color: #3c3c3c;
  font-size: 13px;
  font-weight: normal;
  width: 100%;
}

.interviewSlotModal {
  .modal-dialog {
    max-width: 75%;
  }

  .modal-title {
    margin-left: 35px;
  }
}

.interviewSlotPopupModal {
  .modal-dialog {
    max-width: 80%;
  }

  // .modal-body {
  //   border: 1.5px solid #bfc0c0;
  // }
}

.interviewSLotPopup {
  p {
    font-size: 13px !important;
  }
}

.addPopUpDivmain {
  position: relative;

  .btn {
    width: 100%;
    background: #1b4e9b;
    border: none !important;
    padding: 8px !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
  }

  .modal-header {
    border-bottom: none !important;
    padding: 8px !important;
    position: absolute;
    right: 10px;
    top: 4px;
    z-index: 10000;
  }

  .modal-body {
    .modal-body {
      padding: 32px;

      h4 {
        color: #4b4a4a;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;
      }

      i {
        text-align: center;
        font-style: normal;
        display: block;
        margin-bottom: 10px;
        color: #7e7e7e;
        font-weight: normal;
        font-size: 13px;
      }

      h3 {
        color: #2c2c2c;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 10px;
      }

      p {
        color: #7e7e7e;
        font-weight: normal;
        font-size: 13px;
        margin-bottom: 10px;
      }
    }

    .form-control {
      padding: 10px 20px;
      border: 1px #d0d0d0 solid;
    }

    .form-label {
      color: #2c2c2c;
      font-weight: normal;
    }

    h6 {
      color: #2c2c2c;
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 20px;
    }

    p {
      color: #7e7e7e;
      font-weight: normal;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  .form-control {
    padding: 10px 20px;
    border: 1px #d0d0d0 solid;
  }

  .form-label {
    color: #2c2c2c;
    font-weight: normal;
  }
}

.tableMainPopup {
  margin-bottom: 0px;
  border: 1px #e4e6ea solid;
  background: #fff;

  td {
    border: 1px #e4e6ea solid;
    padding: 13px 9px !important;
    font-size: 10px;
  }

  th {
    border: 1px #e4e6ea solid;
    padding: 9px 19px !important;
  }
}

.TableQuestions {
  table {
    td:first-child {
      text-align: left;
    }

    th {
      text-align: center !important;
    }

    th:first-child {
      text-align: left !important;
    }
  }
}

.submissionTable {
  table {
    th:first-child {
      text-align: center;
    }

    td {
      text-align: left !important;
    }

    td:first-child {
      text-align: center !important;
    }

    td:last-child {
      text-align: center !important;
    }

    th:last-child {
      text-align: center;
    }
  }
}

.profileDropDownMain {
  .dropdown-toggle {
    white-space: nowrap;
    background: none !important;
    border: none;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;

    &:focus {
      box-shadow: none !important;
      background: none !important;
    }

    &::after {
      position: absolute;
      right: -7px;
      top: 15px;
      color: #333;
      font-size: 17px;
    }
  }

  .dropdown-menu.show {
    display: block;
    top: 13px !important;
  }

  .dropdown-item {
    border-bottom: 1px #ddd solid;
    padding: 10px 40px !important;
    position: relative;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: #ddd !important;
      color: #000 !important;
    }

    img {
      position: absolute;
      left: 15px;
      top: 12px;
    }
  }
}

.managementTabMain {
  background: #fff !important;
  border-bottom: none !important;
  border-radius: 30px !important;
  margin-bottom: 10px !important;

  .nav-link {
    color: #000;
    padding: 11px 30px;
    border-radius: 30px !important;
    border: none !important;
    margin-right: 3px !important;

    &:hover {
      color: #fff;
      background: #1b4e9b !important;
    }
  }

  .nav-link.active {
    background: #1b4e9b !important;
    color: #fff !important;
    padding: 11px 30px;
  }
}

.buttonAddMain {
  width: 100%;
  border: none !important;
  border-radius: 0px !important;
  background: #1b4e9b !important;
  padding: 10px !important;

  &:hover {
    background: #000 !important;
  }
}

.defaultTabMain {
  position: relative;
  background: #fff !important;
  border-bottom: none !important;
  display: inline-block !important;
  border-radius: 30px !important;
  margin-bottom: 10px !important;

  .nav-link {
    color: #000;
    padding: 6px 17px;
    font-size: 14px;
    border-radius: 30px !important;
    border: none !important;
    margin-right: 3px !important;

    &:hover {
      color: #fff;
      background: #bccf31 !important;
    }
  }

  .nav-item {
    display: inline-block;
  }

  .nav-link.active {
    background: #bccf31 !important;
    font-size: 14px;
    color: #fff !important;
    padding: 6px 17px;
  }
}

.accordionManagementMain {
  position: relative;

  ul {
    margin: 0;
    padding-left: 0px;
    width: 50%;

    font-weight: 600;
    position: relative;

    li {
      margin-bottom: 9px;
      width: 66%;
      position: relative;

      span {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    div {
      color: #303030;
      position: relative;
      padding-left: 12px;
    }

    .form-check-input {
      height: 24px;
    }

    .form-check-input:checked {
      background-color: #1b4e9b;
      border-color: #1b4e9b;
    }

    .form-check-label {
      color: #bababa;
      font-size: 15px;
      font-weight: normal;
      padding-left: 11px;
      padding-top: 4px;
    }
  }

  .accordion-item {
    border: none !important;
  }

  .accordion-button {
    color: #000;
  }

  .accordion-button::after {
    margin-left: 6px !important;
    background-size: 13px 23px;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none !important;
    background: none !important;
    color: #000 !important;
  }

  .accordion-body {
    padding-top: 14px;

    p {
      color: #a09696;
      font-size: 13px;
      font-weight: normal;
      line-height: 25px;
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .accordion-button:not(.collapsed)::after {
    background-image: #333 !important;
  }

  .accordion-button:focus {
    box-shadow: none !important;
  }
}

.tab-content {
  position: relative;
}

// profile style starts here
.ProfileMainHeader {
  .border-profile {
    border-right: 1px solid #d9d9d985;
  }
}

.ProfilePopupMain {
  .border-left {
    border-right: 1px solid #d9d9d985;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: -33px;
    top: -38px;

    img {
      width: 45px;
      object-fit: contain;
    }
  }

  .form-label {
    margin-bottom: 3px;
    font-size: 13px !important;
    color: #2c2c2c;
  }

  .form-control {
    padding: 12px 30px !important;
  }

  .applyBtn {
    width: 100% !important;
  }

  ::-webkit-file-upload-button {
    color: #000 !important;
    background-color: #f0f0f0 !important;
    border: none !important;
    border-radius: 0px !important;
  }
}

.loadMoreCommentsButton {
  font-size: 15px !important;
  font-weight: 600 !important;
  padding-left: 45px;
  cursor: pointer;
}

.form-switch .form-check-input {
  border-radius: 30px !important;
  width: 41px;
}

.form-check-input:focus {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.activeMaindiv {
  position: relative;
  padding-right: 79px;
  margin-bottom: 20px;

  .form-switch {
    position: absolute;
    right: 0;
    top: 25px;
  }
}

.bottomDropMainall {
  .dropdown {
    position: fixed;
    right: 25px;
    z-index: 100000;
    bottom: 0;
  }

  img {
    width: 50px;
  }

  .dropdown-toggle {
    background: none !important;
    border: none !important;

    &:after {
      display: none !important;
    }
  }
}

.questionBankInputMain {
  margin-bottom: 16px;

  .form-check {
    position: relative;
    margin-bottom: 0px !important;

    .form-check-input {
      position: absolute;
      left: 0;
      top: 2px;
      margin-left: 0px !important;
      margin-top: 0;
    }

    .form-check-input:checked {
      background-color: #1b4e9b !important;
      border-color: #1b4e9b !important;
    }

    .form-check-label {
      margin-bottom: 0px !important;
      font-weight: normal;
      color: #2c2c2c;
      text-transform: capitalize;
    }
  }

  .form-select {
    border: 1px #e2e2e2 solid;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 13px;
  }

  .form-select:focus {
    box-shadow: none;
  }

  .form-label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 6px;
    color: #2c2c2c;
  }

  label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 6px;
    display: block;
    color: #2c2c2c;
  }

  .form-control {
    border: 1px #e2e2e2 solid;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 13px;
  }
}

.testAreaBgMainall {
  border: 1px #ddd solid;
  border-radius: 4px;

  textarea.form-control {
    border: none !important;
    border-radius: 4px;
  }

  .testAreaBgMainallRemove {
    border-top: 1px #ddd solid;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 9px 16px;

    .form-check {
      margin-bottom: 0px;
      position: relative;
      padding-left: 27px;

      .form-check-input {
        border-radius: 50% !important;
        position: absolute;
        left: 0;
        margin-top: 0;
        margin-left: 0px !important;
        width: 20px;
        top: 3px;
        height: 20px;
        border: 2px #cacaca solid !important;
      }
    }

    .form-check-label {
      font-size: 13px !important;
      color: #707070;
    }

    .form-check-input:checked {
      background-color: #1b4e9b !important;
      border-color: #1b4e9b !important;
    }

    .btn {
      background: #e34973;
      border-radius: 4px;
      border: none;
      line-height: 10px;
      font-size: 13px;
      width: 74px;
      text-align: center;
    }
  }
}

.addOptionBtnMain {
  background: #1b4e9b !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  width: 140px !important;
  text-align: center !important;
  margin: 28px auto 20px !important;
  display: block !important;
  border-radius: 30px !important;
  padding: 10px 24px !important;
  font-weight: bold !important;
}

.buttonQuestionFlex {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .addSaveBtnMain1 {
    background: #bccf31 !important;
    border: none !important;
    color: #fff !important;
    font-size: 14px !important;
    width: 225px !important;
    text-align: center !important;
    display: block !important;
    border-radius: 5px !important;
    padding: 10px 24px !important;
    font-weight: 500 !important;
    margin-right: 11px;
  }

  .addSaveBtnMain2 {
    background: #1b4e9b !important;
    border: none !important;
    color: #fff !important;
    font-size: 14px !important;
    width: 225px !important;
    text-align: center !important;
    display: block !important;
    border-radius: 5px !important;
    padding: 10px 24px !important;
    font-weight: 500 !important;
  }
}

.addSaveBtnMain2 {
  background: #1b4e9b !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  width: 225px !important;
  text-align: center !important;
  display: block !important;
  border-radius: 5px !important;
  padding: 10px 24px !important;
  font-weight: 500 !important;
}

.filterBtn {
  border: none;
  color: #ffff;
  font-size: 14px;
  font-weight: 400;
  width: 130px;
  border-radius: 20px;
  padding: 10px;
}

.Blue {
  background-color: #1b4e9b;
  margin-right: 15px;
}

.Green {
  background-color: #bccf31;
}

.GradeFilterGap {
  padding-left: 20px;
}

.GradeFilterSec {
  .form-check-input:focus {
    box-shadow: none !important;
  }

  .form-check-input {
    border-radius: 5px;
  }

  .form-control {
    width: 200px;
  }

  .form-check-input:checked {
    background-color: #1b4e9b;
    border-color: none;
  }

  .form-check-input:active {
    filter: none;
  }
}

.settingDivMain {
  position: relative;
  margin-top: 37px;
  margin-bottom: 20px;

  h2 {
    color: #2c2c2c !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    margin-bottom: 2px !important;
  }

  p {
    color: #7e7e7e;
    font-weight: normal;
    margin-bottom: 0px;
    font-size: 13px !important;
  }

  .form-switch {
    position: absolute;
    right: 0;
    top: 16px;
  }
}

.mainBtnallUpdate {
  float: right !important;
  background: #1b4e9b !important;
  color: #fff !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 10px 44px !important;
  font-size: 13px !important;
}

.offerLetterButton {
  background-color: #1b4e9b !important;
  border: none !important;
}

.toogleBtnmainall {
  border-radius: 67px !important;
  background: #f0f5fb;
  display: inline-block;

  .GreyBtn {
    background: #ededed !important;
    color: #000 !important;
  }

  .btn-primary {
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 19px;
    color: #8b8b8b;
    background: #f0f5fb;
    border: none !important;

    &:hover {
      background: #1b4e9b !important;
      color: #fff !important;
    }
  }

  .activesolutionBtn {
    background: #1b4e9b !important;
    color: #fff !important;
  }
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: none;
  color: #333;
  border: none;
  font-size: 13px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 30px !important;
  font-size: 13px;
}

.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:active {
  color: #fff !important;
}

.previewInputMain {
  position: relative;

  .btn-primary {
    width: 133px !important;
    border-radius: 0px !important;
    background: #1b4e9b !important;
    height: 100%;
    border-radius: 3px !important;
    font-size: 14px;

    img {
      width: 10px;
      margin-top: -3px;
    }
  }

  .previewInputMainBtn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}

.ulCheckBoxnav {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding-right: 10px;
  }
}

// grades style starts here
.GradesMain {
  margin-bottom: 10px;

  .form-control {
    border: none !important;
  }
}

.GradeTableNew {
  .table {
    td {
      text-align: center;
      padding: 10px 8px !important;
    }

    .ProfileNameTd {
      width: 300px !important;
    }
  }
}

.GradeTable {
  .table {
    td {
      text-align: center;
      padding: 12px 6px;
    }
  }
}

.StudenGradeMain {
  .form-select {
    width: 240px !important;
    display: block;
  }
}

.GradeTableStudent {
  table {
    td:nth-child(2) {
      text-align: left;
    }

    th:nth-child(2) {
      text-align: left !important;
    }

    th:first-child {
      text-align: left !important;
    }

    .LeftTableItem {
      text-align: left;
    }

    td:first-child {
      text-align: left;
    }

    .TableItemTwo {
      text-align: center !important;
    }
  }
}

// elective style starts here
.BoxElect {
  position: relative;
  margin-bottom: 10px;

  .NewBtnElective {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: none;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 400;
    padding: 6px 15px;
    color: #fff;
    width: 90px;
  }

  .Pink {
    background-color: #e34973;
  }

  .DisableBtn {
    background-color: #dddadb;
  }

  .BlueReg {
    background-color: #1b4e9b;
  }
}

.NewBtnElectivePublish {
  position: absolute;
  right: 10px;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px;
  color: #fff;
  width: 150px;
}

.BlueReg {
  background-color: #1b4e9b;
}

.toggleGrey {
  background: #ededed !important;
}

.commonFormStyle {
  input::placeholder {
    font-size: 13px !important;
  }

  .form-label {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #2c2c2c !important;
    margin-bottom: 5px;
  }

  .form-select {
    padding: 7px 8px;
    background-size: 12px 14px !important;
    opacity: 1;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #e2e2e2 !important;
    border-radius: 3px;
    color: #1c1919;
    width: 100% !important;
    display: block;
  }

  .form-select:focus {
    border-color: #86b7fe !important;

    box-shadow: none;
  }

  .form-control:focus {
    border-color: #86b7fe !important;

    box-shadow: none;
  }

  .form-control {
    padding: 7px 10px;
    background: #fff;
    font-size: 14px;
    font-weight: 400 !important;
    width: 100%;
    color: #000;
    border-radius: 3px;
    transition: width 0.5s;
    margin-bottom: 15px;
  }

  .form-control:focus {
    box-shadow: none !important;
  }

  .colMainSpace {
    padding: 0 3px !important;
  }
}

.questionDeatilBgMain {
  background: #fff;
  padding: 12px 20px 19px !important;
  margin-bottom: 10px !important;
  border-bottom: none !important;
  border-radius: 0 0 5px 5px !important;

  li {
    border-radius: 30px;
    background: #f4f4f4;
    margin-right: -20px;
    padding: 0 6px;

    .nav-link.active {
      border: none !important;
      color: #fff !important;
      background: #1b4e9b !important;
      border-radius: 30px;
    }
  }

  .nav-link:hover {
    border: none !important;
  }

  .nav-link {
    border: none !important;
    border-radius: 30px !important;
    color: #28374d !important;
    font-size: 11px !important;
  }

  .questionDeatilBgMain1 {
    background: #fff !important;
    padding: 30px !important;
  }
}

.back-btn {
  background: none !important;
  border: none !important;
  padding: 0px;
}

.MainDivClear {
  position: relative;

  .BtnClear {
    position: absolute;
    right: 10px;
    top: 27px;
    border: none;
    background: none;

    .ClearImg {
      width: 20px;
    }
  }
}

.bottomBtnmain {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  h4 {
    background: #efefef;
    font-size: 13px;
    color: #aaa;
    font-weight: normal;
    padding: 9px;
    margin-bottom: 0px;
  }

  .btn {
    background: #1b4e9b;
    border-radius: 7px;
    color: #fff;
    padding: 5px 32px;
    font-size: 14px;
  }

  .removeBtn {
    background: #e34973 !important;
    border: 1px #e34973 solid;
  }
}

.widthMain1 {
  width: 19px !important;
}

.uiNavBtn {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding: 0 3px;
  }
}

.newScheduledPopup {
  .modal-header {
    padding: 0px;
  }

  .modal-title {
    font-size: 17px;
    color: #4b4a4a;
    margin-bottom: 10px;
    font-weight: 700;
  }

  .modal-body {
    padding: 0px;
  }

  .modal-content {
    padding: 24px;
    position: relative;
  }

  .btn-close {
    position: absolute !important;
    right: -43px !important;
    top: -32px !important;
    background: url(../src/assets/images/popup-close.webp) center center
      no-repeat !important;
    width: 55px !important;
    height: 55px !important;
    background-size: 40px !important;
    padding: 0px !important;
    opacity: 1 !important;
    box-shadow: none !important;
    margin: 0 !important;
  }
}

.createBtnmainall {
  width: 100%;
  background: #1b4e9b !important;
  padding: 6px;
  border: 1px #1b4e9b solid !important;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;

  &:hover {
    background: #000 !important;
  }
}

.btnScheduled {
  position: absolute;
  right: 30px;
  top: 26px;
  color: #fff;
  background: #1b4e9b !important;
  border-radius: 30px !important;
  padding: 5px 20px !important;
  font-size: 13px !important;

  &:hover {
    background: #000 !important;
  }
}

.btnScheduled1 {
  color: #fff;
  background: #1b4e9b !important;
  border-radius: 30px !important;
  padding: 5px 20px !important;
  font-size: 13px !important;
  border: 1px #1b4e9b solid !important;

  &:hover {
    background: #000 !important;
  }
}

.viewResultBtn {
  color: #fff;
  background: #1b4e9b !important;
  border-radius: 30px !important;
  padding: 5px 20px !important;
  font-size: 13px !important;
  border: 1px #1b4e9b solid !important;
}

.btnScheduled2 {
  color: #fff;
  border: 1px #bccf31 solid !important;
  background: #bccf31 !important;
  border-radius: 30px !important;
  padding: 5px 20px !important;
  font-size: 13px !important;

  &:hover {
    background: #000 !important;
  }
}

.newSectionBtnMain {
  background: #bccf31;
  border: none;
  border-radius: 30px;
  position: relative;
  padding: 7px 14px 7px 36px;
  color: #fff;
  font-size: 14px;
  width: 150px;
  margin-bottom: 10px;
  transition: 0.4s;
  float: right;
}

.link-color {
  color: #1b4e9b;
  cursor: pointer;
}

// .new-flex {
//   justify-content: center;
// }

.commonSearch {
  position: relative;

  .clearClose {
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: 100;
    cursor: pointer;

    img {
      width: 18px;
    }
  }

  .filter {
    cursor: pointer;

    .filterLabel {
      border: none;
      padding: 10px 10px;
      background: #fff;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      color: #000;
      padding-left: 45px;
    }
  }

  .searchWrapperMain {
    position: relative;
    border-radius: 30px;

    .searchWrapperMainDiv {
      position: absolute;
      left: 6px;
      top: 4px;
      z-index: 100;

      img {
        width: 33px;
      }
    }
  }

  .search {
    position: relative;

    .clearClose {
      position: absolute;
      right: 15px;
      top: 8px;
      z-index: 100;
      cursor: pointer;

      img {
        width: 18px;
      }
    }

    .form-control {
      border: 1px solid #e0e0e0;
      padding: 10px 55px;
      background: #fff !important;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 300;
      width: 100%;
      padding-left: 50px;
      transition: width 0.5s;
    }
  }
}

.AreaPlannerMain {
  .form-check-input {
    height: 20px;
    width: 3em !important;
  }

  .form-check-input:checked {
    background-color: #1b4e9b;
    border-color: #1b4e9b;
  }

  .form-check-label {
    color: #bababa;
    font-size: 15px;
    font-weight: normal;
    padding-left: 11px;
    padding-top: 4px;
  }

  .tableArea {
    table {
      th:first-child {
        text-align: left !important;
      }

      td:first-child {
        text-align: left !important;
      }
    }
  }
}

.CommonButtonCls {
  .nextbtn {
    background: #1b4e9b;
    float: right;
  }

  .cancelbtn {
    background: #e0e0e0;
    float: right;
    margin-right: 15px;
    color: black !important;
  }

  .backtbtn {
    background: #d5d5d5;
    float: left;
    color: #958f8f !important;
    margin-right: 15px;
  }

  .savebtn {
    background: #bccf31;
    float: left;
  }

  .nextbtnMain {
    border-radius: 4px;
    padding: 7px 20px;
    height: 40px;
    font-weight: normal !important;
    color: #fff;
    font-weight: 500 !important;
    border: none;
    margin-top: 30px;
    margin-bottom: 18px;
    font-size: 14px;
    width: 190px;
    line-height: 0px;

    @media screen and (max-width: 1199px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 570px) {
      width: 100%;
      padding: 10px 10px;
    }
  }
}

.ProgramView {
  .toogleBtnmainall {
    .btn-primary {
      padding: 5px 20px !important;
    }
  }
}

.FlagReview {
  position: relative;

  .FlagStatus {
    position: absolute;
    right: 20px;
    top: -10px;
    border-radius: 0px;
    text-align: center;
    cursor: pointer;
    width: 110px;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 20px solid #ffff;
    border-bottom: 20px solid transparent;
  }

  .TextFlag {
    font-size: 13px;
    color: #fff;
    margin-top: -8px;
  }

  .BlueFlag {
    background-color: #2959a1;
  }

  .PinkFlag {
    background-color: #e34973;
  }

  .GrayFlag {
    background-color: #d1ccbc;
  }
}

.fileStyle {
  .modal-dialog {
    max-width: 75%;
  }

  ::-webkit-file-upload-button {
    color: #fff !important;
    background-color: #1b4e9b !important;
    border: none !important;
    border-radius: 0px !important;
    margin: 1px !important;
    margin-right: 6px !important;
    border-radius: 3px !important;
  }
}

.CheckStyle {
  .form-check-input:active {
    filter: none;
  }

  .form-check-input:checked {
    background-color: #1b4e9b;
    border-color: #1b4e9b;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .form-check-input {
    border: solid 2px #acacac;
    width: 17px;
    height: 17px;
    border-radius: 0px;
    appearance: auto;
  }
}

// toster styles
.Toastify {
  .Toastify__toast--error {
    @include TosterCommon();
    background: #f9461d !important;
  }

  .Toastify__toast--success {
    @include TosterCommon();
    background: #43d787 !important;
  }

  .Toastify__toast--success::before {
    content: url("./assets/images/sucess-toast.svg"); // Your svg Path
    transform: scale(0.7);
    position: relative;
    z-index: 100000;
    left: 0px;
    top: 3px;
  }

  .Toastify__toast--warning {
    @include TosterCommon();
    background: #ffbb2d !important;
  }

  .Toastify__toast--warning::before {
    content: url("./assets/images/warn-toast.svg"); // Your svg Path
    transform: scale(0.7);
    position: relative;
    z-index: 100000;
    left: 0px;
    top: 3px;
  }

  .Toastify__close-button--light {
    color: #fff !important;
    opacity: 0.4;
  }

  .Toastify__close-button {
    display: none !important;
  }

  .Toastify__toast--error::before {
    content: url("./assets/images/close-toast.svg");
    transform: scale(0.7);
    position: relative;
    z-index: 100000;
    left: 0px;
    top: 3px;
  }

  .Toastify__toast-icon {
    display: none !important;
  }

  .Toastify__toast {
    padding: 0px !important;
  }
}

.newFlexAssignment {
  align-items: center;
}

.startExamBtnmain {
  background: #1b4e9b !important;
  padding: 6px 16px !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px !important;
  font-weight: normal !important;
  border: none !important;
  border-radius: 3px !important;

  &:hover {
    background: #000 !important;
  }
}

.scheduledStartExamBtn {
  background: #1b4e9b !important;
  padding: 6px 16px !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px !important;
  width: 100% !important;
  font-weight: normal !important;
  border: none !important;
  border-radius: 3px !important;
  // &:hover{
  //   background: #000!important;
  // }
}

.classMarginSpace {
  padding: 0 5px !important;
  margin-bottom: 10px;
}

.classMarginSpaceRow {
  margin: 0 -5px !important;
}

.formMainScheduledCheck {
  .form-check-label {
    font-size: 13px;
    color: #707070;
    font-weight: normal;
    padding-left: 5px;
  }
}

.finishBtnMain {
  margin: 15px auto 0px !important;
  display: block !important;
  border: solid 1px #1b4e9b !important;
  background-color: #1b4e9b !important;
  font-size: 14px !important;
  line-height: 0px !important;
  padding: 17px 0px !important;
  border-radius: 0px !important;
  width: 100% !important;

  &:hover {
    background-color: #000 !important;
  }
}

.saveExitBtn {
  background: #e34973 !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  width: 150px !important;
  text-align: center !important;
  display: block !important;
  border-radius: 5px !important;
  padding: 10px 24px !important;
  font-weight: 500 !important;

  &:hover {
    background: #000 !important;
  }
}

.clearAnswerBtn {
  background: #bccf31 !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  width: 150px !important;
  text-align: center !important;
  display: block !important;
  border-radius: 5px !important;
  padding: 10px 24px !important;
  font-weight: 500 !important;

  &:hover {
    background: #000 !important;
  }
}

.markReviewBtn {
  background: #1b4e9b !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  // width: 150px !important;
  text-align: center !important;
  display: block !important;
  border-radius: 5px !important;
  padding: 10px 24px !important;
  font-weight: 500 !important;

  &:hover {
    background: #000 !important;
  }
}

.clearNoBtn {
  background: #bccf31 !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  width: 150px !important;
  text-align: center !important;
  display: block !important;
  border-radius: 5px !important;
  padding: 10px 24px !important;
  font-weight: 500 !important;

  &:hover {
    background: #000 !important;
  }
}

.markYesBtn {
  background: #1b4e9b !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  width: 150px !important;
  text-align: center !important;
  display: block !important;
  border-radius: 5px !important;
  padding: 10px 24px !important;
  font-weight: 500 !important;

  &:hover {
    background: #000 !important;
  }
}

.viewResultBtnMain {
  background: #1b4e9b !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
  width: 150px !important;
  text-align: center !important;
  display: block !important;
  border-radius: 5px !important;
  padding: 10px 24px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  margin: 0 auto !important;

  &:hover {
    background: #000 !important;
  }
}

.btn-close {
  position: absolute !important;
  right: -45px !important;
  top: -32px !important;
  background: url(../src/assets/images/popup-close.webp) center center no-repeat !important;
  width: 55px !important;
  height: 55px !important;
  background-size: 40px !important;
  padding: 0px !important;
  opacity: 1 !important;
  box-shadow: none !important;
  margin: 0 !important;
}

.popupNavResult {
  margin: 0 0 15px;
  padding: 0;

  li {
    display: inline-block;
    position: relative;
    padding: 0 27px;

    h4 {
      font-size: 10px !important;
      font-weight: normal;
      color: #7e7e7e;
      text-align: center;

      span {
        display: block;
        font-weight: bold;
        font-size: 14px;
        margin-top: 3px;
      }
    }

    &:before {
      position: absolute;
      right: 0;
      top: 6px;
      background: #ddd;
      content: "";
      width: 1px;
      height: 16px;

      &:last-child {
        display: none !important;
      }
    }
  }
}

.leftBorderMain1 {
  border-left: 1px #ddd solid;
  padding-left: 24px;
}

.analysisTabSec {
  border-bottom: none !important;
  margin-bottom: 10px !important;

  .nav-link.active {
    padding: 9px 27px !important;
    background: #bccf31 !important;
  }

  .nav-link {
    padding: 9px 27px !important;
    background: #fff !important;
    margin-right: -18px;
  }
}

.marginSpaceMain {
  padding: 0 4px !important;
}

.spaceRowMain {
  margin: 0 -4px !important;
}

.h3Commonmain {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: left;
  margin-top: 10px;
}

.publishedMain {
  background: #c2fae2;
  border-radius: 30px;
  color: #000;
  padding: 3px 20px;
  font-size: 12px;
}

.daftMain {
  background: #ffcece;
  border-radius: 30px;
  color: #000;
  padding: 3px 20px;
  font-size: 12px;

  .h3Commonmain {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 10px;
  }
}

.jobDeatilsTabMain {
  border-bottom: none !important;
  background: #fff;
  padding: 30px !important;

  .nav-link {
    position: relative;
    padding-top: 69px;
    border: none !important;
  }

  .nav-item {
    position: relative;
    z-index: 10;

    span {
      color: #d7d4d4 !important;
    }

    &:before {
      position: absolute;
      z-index: -1;
      left: 22px;
      top: 34px;
      background: #ddd;
      content: "";
      width: 100%;
      height: 1px;

      &:last-child {
        display: none !important;
      }
    }

    .active {
      background: none !important;

      span {
        color: #1b4e9b !important;
      }
    }

    span {
      img {
        position: absolute;
        left: 50%;
        top: 0;
        width: 60px;
        text-align: center;
        margin-left: -30px;
        background: #fff;
      }
    }
  }
}

.tabMainContent {
  padding: 30px !important;
  background: #fff !important;
  margin-top: 10px;
}

.jobDetilDivAll {
  .form-control {
    background-size: 12px 14px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    color: #1c1919;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    opacity: 1;
    padding: 7px 15px;
    width: 100% !important;
    box-shadow: none !important;
  }

  .form-select {
    background-size: 12px 14px !important;
    border: 1px solid #e2e2e2 !important;
    border-radius: 4px !important;
    color: #1c1919;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    opacity: 1;
    padding: 7px 15px;
    width: 100% !important;
    box-shadow: none !important;
  }

  .form-label {
    color: #2c2c2c !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-bottom: 5px;
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    padding-left: 0;
  }

  h3 {
    font-size: 13px;
    font-weight: normal;
    padding-left: 0;
    color: #403e3e !important;
    margin-top: 10px;
  }
}

.btnMainFloat {
  margin: 0;
  padding: 0%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.backBtnMainall {
  background: none !important;
  color: #505050 !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 10px 44px !important;
  font-size: 13px !important;
  border: 1px #505050 solid !important;
}

.spaceMainFlex {
  margin-bottom: 30px;
}

.inputMain {
  background-size: 12px 14px !important;
  border: none !important;
  border-radius: 0px !important;
  color: #1c1919;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px !important;
  opacity: 1;
  padding: 7px 15px;
  width: 100% !important;
}

.colspanSpace {
  padding-left: 0px !important;
}

.checkBoxCommonstyle {
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 12px !important;
    font-weight: normal !important;
  }

  .form-check-input {
    border-radius: 4px !important;
    border: solid 1px #b7b7b7 !important;
    width: 20px !important;
    height: 20px !important;
  }

  .form-check-label {
    color: #403e3e !important;
    font-size: 12px !important;
    font-weight: normal !important;
    padding-left: 2px !important;
    padding-top: 0px !important;
  }
}

.borderCommonAccordion {
  .accordion-body {
    padding: 8px 0px !important;
  }

  .accordion-item {
    border-bottom: 1px #ddd solid !important;
    border: none;
    margin-bottom: 0px;
    padding: 10px 0px;
  }

  .accordion-button {
    padding: 0px !important;
    color: #403e3e !important;
    background: #fff !important;
    font-size: 13px !important;
    font-weight: normal !important;

    &:after {
      background-size: 12px 20px !important;
    }
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
  }
}

.talentPollTab {
  border-bottom: none !important;
  margin-bottom: 20px !important;

  .nav-link {
    background: #fff !important;
    border-radius: 30px !important;
    border: 1px #fff solid !important;
    width: 148px;
    padding: 6px;
    color: #000;
  }

  .nav-link.active {
    background: #bccf31 !important;
    color: #fff !important;
  }

  .nav-item {
    margin-right: -29px;

    &:hover {
      color: #000 !important;
    }
  }
}

.borderCommonAccordion1 {
  img {
    margin: 0 auto;
    display: block;
  }

  .accordion-body {
    padding: 8px 0px !important;
  }

  .accordion-item {
    border: none !important;
    margin-bottom: 0px;
    padding: 10px 0px;
  }

  .accordion-button {
    padding: 0px !important;
    color: #403e3e !important;
    background: #fff !important;
    font-size: 20px !important;
    font-weight: normal !important;
    margin-bottom: 27px;

    &:after {
      background-size: 12px 20px !important;
    }
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
  }
}

.commonBtnMainall {
  background: none !important;
  color: #000 !important;
  width: 100%;
  position: relative !important;
  border-radius: 60px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  border: 1px #fff solid !important;
  margin-bottom: 6px !important;
  text-align: left !important;
  padding-left: 40px !important;

  img {
    width: 17px;
    position: absolute;
    left: 14px;
    top: 10px;
  }

  &:hover {
    border: 1px #1b4e9b solid !important;
    color: #1b4e9b !important;
  }
}

.popUpMarkedJoin {
  .modal-content {
    padding: 23px !important;

    h4 {
      border-bottom: 1px #707070 solid;
      font-size: 20px;
      text-transform: uppercase;
      padding-bottom: 12px;
      margin-bottom: 14px;
      color: #4b4a4a;
    }

    p {
      font-size: 15px;
      font-weight: normal;
      color: #4b4a4a;

      margin-bottom: 20px;
    }

    button {
      background-color: #1b4e9b;
      width: 193px;
      margin: 0 auto;
      display: block;
      border: none;
    }
  }

  .modal-header {
    padding: 0px !important;
  }
}

.borderSpaceMin {
  border-right: 1px #ddd solid;
  padding: 0 26px !important;

  h3 {
    color: #1c1b1b !important;
    background: #fff !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    margin-bottom: 9px;
  }

  p {
    color: #747272;
    font-size: 13px;
    font-weight: normal;
    line-height: 25px;
    margin-bottom: 0px;
  }
}

.blueColorMain {
  color: #1b4e9b !important;
}

.popUpfeedback {
  .modal-content {
    padding: 23px !important;

    h2 {
      border-bottom: 1px #d5d5d5 solid;
      font-size: 18px;
      text-transform: uppercase;
      padding-bottom: 12px;
      margin-bottom: 14px;
      color: #000;
    }

    p {
      font-size: 15px;
      font-weight: normal;
      color: #4b4a4a;

      margin-bottom: 20px;
    }

    button {
      background-color: #1b4e9b;
      width: 193px;
      margin: 0 auto;
      display: block;
      border: none;
    }
  }

  .modal-header {
    padding: 0px !important;
  }
}

.labelBold {
  color: #000;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: bold;
}

.addBtNav {
  margin-top: 20px;

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 4px !important;
    }

    button {
      background: #1b4e9b !important;
    }

    .cancelBtnMain {
      background: none !important;
      border: 1px #ddd solid !important;
      color: #695c5c !important;
    }
  }
}

// attendence management style starts here
.AttendencExpandDiv {
  .accordion-button {
    justify-content: space-between;
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;

    &::after {
      display: none !important;
    }
  }

  .accordion-button:not(.collapsed) {
    background: none !important;
  }

  .accordion-item {
    border: none !important;
  }

  .accordion-body {
    padding: 10px !important;
    margin: 10px;
  }

  .innerTd {
    font-size: 13px !important;
    font-weight: 400 !important;
  }

  .borderTable {
    border: 1px solid #d9d9d985;
    padding: 15px;
  }
}

.EmployeeAttendTable {
  margin-top: 0px !important;
}

.readMorePara {
  white-space: pre-line;
}

// pagination style change space
.PaginationStyleChange {
  .paginationDisplay {
    padding-right: 35px;
  }
}

// adjust cls starts here
// this class is only for adjusting close button for now need to delete once all modal sizes are changed

.ScamCloseCls {
  .modal-header {
    .btn-close {
      top: -40px !important;
      right: -80px !important;
    }

    .btn-close:focus {
      box-shadow: none;
    }
  }
}

#screen-share-canvas {
  height: 800px !important;
  width: 800px !important;
}

// grade edit button
.custom-edit-button {
  background-color: #ffff;
  border: none;
  background-image: url("./assets/images/action-img-1.svg");
  background-size: 36px;
  height: 32px;
  width: 31px;
  z-index: 100;
  opacity: 100;
}

// radio btn space
.radioClass {
  .form-check-label {
    margin-right: 15px;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .form-check-input:active {
    filter: none;
  }
}

@keyframes blink {
  50% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
  font-size: 23px;
}

.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}

.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}

.desktopView {
  display: block;
}

.mobileView {
  display: none;
}

@media screen and (max-width: 767px) {
  .desktopView {
    display: none !important;
  }

  .mobileView {
    display: block !important;
  }
}

#zmmtg-root {
  display: none;
}

.dropdownMain1 {
  .dropdown-toggle {
    background: none !important;
    border: none !important;
    color: #292727 !important;
    font-size: 14px;
    font-weight: 400;

    &::after {
      margin-left: 10px;
      vertical-align: 3px;
    }

    &:hover {
      color: #292727;
    }
  }
}

.userStatus {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid grey;
}

.online {
  background: greenyellow;
}

.offline {
  background: red;
}

.message_wrapper {
  margin-top: 5px;
  padding: 0px 5px;
}

.message_wrapper img {
  width: 100%;
  border-radius: 5px;
}

.message_wrapper p {
  padding: 10px;
  display: inline-block;
  max-width: 50%;
  text-align: left;
  border-radius: 5px;
}

.message_wrapper small {
  display: inline-block;
  margin-top: 15px;
  opacity: 0.8;
}

.message_wrapper.own {
  text-align: right;
}

.me {
  background: blue;
  color: white;
}

.friend {
  background: white;
}

.selected_user {
  background: grey;
}

.truncate {
  font-size: 14px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate strong {
  margin-right: 10px;
}

.offcanvas-header {
  .btn-close {
    top: -7px !important;
    position: absolute !important;
    right: 0px !important;
  }
}

.downloadLink {
  a {
    display: block;

    img {
      width: 200px;
    }
  }
}

.fileStyleOrdinary {
  ::-webkit-file-upload-button {
    color: #000 !important;
    background-color: #ede8e8 !important;
    border: none !important;
    border-radius: 0px !important;
    margin: 1px !important;
    margin-right: 6px !important;
    border-radius: 3px !important;
  }
}

.switchMainFlex {
  display: flex;
  float: right;
  align-items: center;

  .editActionForm {
    cursor: pointer;

    img {
      width: 20px;
    }
  }

  .form-check-inline {
    margin-right: 0.5rem !important;
  }

  .newCheckForm {
    .form-check-input[type="checkbox"] {
      border-radius: 3px !important;
      width: 23px;
      height: 23px;
      margin-top: 0px;
      margin-left: 10px;
    }

    .form-check-input:checked {
      background-color: #09e03a !important;
      border-color: #09e03a !important;
    }
  }
}

.formHead {
  h5 {
    margin-top: 1px;
    margin-right: 20px;
    margin-bottom: 0px;
  }
}

.preview-modal-class {
  .modal-content {
    background: #000 !important;
  }
}

.NotificationStyleCls {
  .card {
    border: none !important;

    .card-body {
      padding: 0rem 0rem !important;
      margin-bottom: 25px;
    }

    .card-header {
      margin-bottom: 0;
      background-color: #fff !important;
      border-bottom: none !important;
      border: none;
      padding: 0rem 0rem !important;
    }
  }
}

.NotificationPopupMain {
  .modal-dialog {
    margin-right: 20px !important;
  }

  .modal-body {
    padding: 10px 20px 10px;
  }
}

.react-responsive-modal-modal {
  width: 95% !important;
  background: black !important;
}

.slideMainAll1 {
  .nav .active {
    background-color: #bccf31 !important;
    z-index: 1;
    text-align: center !important;
    display: inline-block;
    color: #fff !important;
  }

  .leftButtonActive {
    background-color: #bccf31 !important;
    color: #fff;
    z-index: 1;
    text-align: center !important;
    display: inline-block;

    &:hover {
      color: #ffff;
    }
  }

  .leftButton {
    color: #000 !important;
    text-align: center !important;
    background-color: #f2ecec !important;
    border: none;
    font-size: 14px;
    display: inline-block;
    position: relative;
  }

  .ActiveLeft {
    position: relative;
    margin-left: -28px;
  }

  .nav-link {
    padding: 8px 50px !important;
    text-align: center;
    border-radius: 28px !important;
  }

  .nav-link.active {
    padding: 8px 50px !important;
  }
}

.LearningMain {
  .form-control:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }

  .accordion-item {
    border: none;
    margin-bottom: 10px;
    border-radius: 0px !important;
  }

  .accordion-body {
    padding: 10px 15px 25px !important;

    .form-control {
      border: none !important;
      background: #f0f5fb;
      border-radius: 30px;
      font-weight: 300;
      width: 100%;
      box-shadow: none !important;
      color: #969696;
      font-size: 14px;
      padding: 13px 58px 13px 26px;
    }

    .placeholder {
      font-size: 13px;
    }
  }

  .accordion-button {
    color: #000000;
    box-shadow: none !important;
    padding: 15px 15px !important;
    opacity: 1;
    box-shadow: none;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;

    position: relative;

    &::after {
      display: none;
    }

    .commentsForum {
      display: flex;
      position: absolute;
      right: 15px;
    }

    .accordion-button:focus {
      box-shadow: none !important;
    }

    .activeBtn {
      .dropdown-toggle {
        border-radius: 0px;
      }
    }
  }

  .accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #ffff !important;
    box-shadow: none !important;

    border-radius: 0px !important;
    position: relative;

    &::after {
      position: absolute;
      right: 0px;
      transform: rotate(0deg) !important;
      background-size: 17px !important;
    }
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    box-shadow: none !important;
  }
}

.profileDropDownMain1 {
  h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 5px;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: #3260a5;
    }
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin: 10px 15px 1px;
  }

  .dropdown-toggle {
    white-space: nowrap;
    border-radius: 0px;
    width: 110px;
    font-size: 14px;
    text-align: left;

    &:focus {
      box-shadow: none !important;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu.show {
    display: block;
    top: -1px !important;
    border-radius: 0px;
    height: 400px;
    overflow: scroll;
    border: none;
  }

  .dropdown-item {
    border: none !important;
    padding: 8px 50px !important;

    position: relative;

    &:hover {
      background: #fff !important;
      color: #000 !important;
    }

    img {
      position: absolute;
      left: 15px;
      top: 17px;
      width: 15px;
    }
  }
}

.profileDropDownMain2 {
  h5 {
    font-size: 15px;
    font-weight: 500;
    margin: 10px 15px 1px;

    img {
      width: 15px;
      margin-right: 10px;
    }
  }

  .dropdown-toggle {
    white-space: nowrap;
    border-radius: 0px;
    text-align: center;
    width: 50px;
    height: 33px;

    &:focus {
      box-shadow: none !important;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu.show {
    display: block;
    top: -1px !important;
    border-radius: 0px;
    height: 400px;
    overflow: scroll;
    border: none;
  }

  .dropdown-item {
    border: none !important;
    padding: 2px 15px !important;
    position: relative;

    &:hover {
      background: #fff !important;
      color: #000 !important;
    }
  }
}

.profileTable {
  .table {
    border: 1px solid #ddd;

    td,
    th {
      border: 1px solid #ddd;
      text-align: left !important;
    }
  }
}

.spanLinkStyle {
  font-size: 12px;
  color: red;
  float: right;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 400;
}

.tox-notification__icon {
  display: none !important;
}

.tox-notification__body {
  display: none !important;
}

.tox-notification__dismiss {
  display: none !important;
}

.tox-notification {
  padding: 0 !important;
  border-width: 0 !important;
}

.communicationLogAccordion {
  .accordion-button::after {
    background-image: none !important;
  }

  .accordion-button:not(.collapsed) {
    background-color: white !important;
    box-shadow: none !important;
  }

  .accordion-button:focus {
    box-shadow: none !important;
  }
}

.work-experience-section {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
}

.ql-editor {
  p {
    color: black !important;
  }
}

.jodit {
  p {
    color: black !important;
  }
  ul {
    padding-left: 20px !important;
    list-style-type: disc;
  }
  ol {
    padding-left: 20px !important;
    list-style-type: disc;
  }
}

.jodit-editor {
  * {
    color: initial !important; /* Reset text color to default */
    font-family: initial; /* Reset font family to default */
    font-size: initial !important; /* Reset font size to default */
    background: #fff !important; /* Set default background color */
    padding: 0 !important ; /* Remove any padding */
    margin: 0 !important; /* Remove any margin */
    line-height: initial !important;
  }
}
.campusDiv {
  .colMainSpace {
    padding: 0 5px !important;
  }

  .webTitlecolMainSpace {
    height: 100px;
  }

  .webDescriptioncolMainSpace,
  .webTiltlecolMainSpace {
    padding: 0 5px !important;
    width: 100%;

    .webTitleField {
      .form-label {
        font-size: 13px !important;
        font-weight: 600 !important;
        font-family: "Poppins", sans-serif !important;
      }
    }
  }

  .btn-primary:focus {
    box-shadow: none !important;
  }

  // span {
  //   // font-size: 15px;
  //   // font-weight: 700;
  // }

  p {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #2c2c2c !important;
    margin-bottom: 5px;
    padding-left: 0px;
    text-align: left !important;
    line-height: 25px !important;
    margin: 5px 0px 10px !important;
  }

  .savebtnMain {
    background: #bccf31;
    border: 1px solid #bccf31;
    border-radius: 4px;
    padding: 10px 20px;

    height: 40px;
    font-weight: normal !important;
    color: #fff !important;
    font-weight: 500 !important;
    border: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 0px;

    @media screen and (max-width: 1199px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 570px) {
      width: 100%;
      padding: 10px 10px;
    }

    &:hover {
      background: #1b4e9b;
    }
  }

  .backbtnMain {
    background: #1b4e9b;
    border: 1px solid $sub-text;
    border-radius: 4px;
    padding: 10px 40px;

    height: 40px;
    margin-right: 5px;
    font-weight: normal !important;
    color: #fff !important;
    font-weight: 500 !important;
    border: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 0px;

    @media screen and (max-width: 1199px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 570px) {
      width: 100%;
      padding: 10px 10px;
    }

    &:hover {
      background: #bccf31;
    }
  }

  .nextbtnMain {
    background: #1b4e9b;
    border: 1px solid #1b4e9b;
    border-radius: 4px;
    padding: 10px 40px;

    height: 40px;
    font-weight: normal !important;
    color: #fff !important;
    font-weight: 500 !important;
    float: right;
    border: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 0px;

    &:hover {
      background: #bccf31;
    }

    @media screen and (max-width: 1199px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 570px) {
      width: 100%;
      padding: 10px 10px;
    }

    &:hover {
      background: #bccf31;
    }
  }

  .form-label {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #2c2c2c !important;
    margin-bottom: 5px;
  }

  .form-select {
    padding: 7px 8px;
    background-size: 12px 14px !important;
    opacity: 1;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #e2e2e2 !important;
    border-radius: 4px !important;
    color: #1c1919;
    width: 100% !important;
    display: block;
    padding-right: 33px !important;
  }

  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }

  .form-control:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }

  .input-group {
    display: block !important;
  }

  .form-control,
  .react-datepicker__input-container input {
    padding: 7px;
    background-size: 12px 14px !important;
    opacity: 1;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #e2e2e2 !important;
    border-radius: 4px !important;
    color: #1c1919;
    width: 100% !important;
    display: block;
  }

  .inputError,
  .react-datepicker__input-container .inputError {
    border: 1px solid red !important;
  }
}
