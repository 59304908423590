* {
  width: "100%";
  height: "100vh";
}

.main {
  background-color: #ffff;
  padding: 80px;

  h1 {
    font-size: 80px;
    font-weight: 800;
    color: #1b4e9b;
  }

  h3 {
    font-size: 30px;
  }

  button {
    background-color: #bccf31;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 7px 35px;
    font-weight: 600;
  }
}
